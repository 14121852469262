define('ember-cli-bkf-core/enums/professional-status', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.professionalStatus = undefined;
  var professionalStatus = exports.professionalStatus = [{
    text: 'Assalariado',
    value: 'clt'
  }, {
    text: 'Autônomo',
    value: 'self_employed'
  }, {
    text: 'Profissional Liberal',
    value: 'freelancer'
  }, {
    text: 'Empresário',
    value: 'businessman'
  }, {
    text: 'Funcionário Público',
    value: 'civil_servant'
  }, {
    text: 'Aposentado',
    value: 'retired'
  }, {
    text: 'Outro',
    value: 'other'
  }];

  exports.default = professionalStatus;
  var values = exports.values = _lodash.default.map(professionalStatus, 'value');
});