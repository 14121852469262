define('ember-cli-bkf-core/models/loan/auto-refi/bkf/attachment', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    document: _emberData.default.belongsTo('loan/auto-refi/bkf/document'),

    filename: _emberData.default.attr('string'),
    uploadedBy: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date')
  });
});