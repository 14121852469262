enifed('ember-views/views/states/in_dom', ['exports', 'ember-utils', 'ember-metal', 'ember-debug', 'ember-views/views/states/has_element'], function (exports, _emberUtils, _emberMetal, _emberDebug, _has_element) {
  'use strict';

  /**
  @module ember
  @submodule ember-views
  */

  var inDOM = Object.create(_has_element.default);

  (0, _emberUtils.assign)(inDOM, {
    enter: function (view) {
      // Register the view for event handling. This hash is used by
      // Ember.EventDispatcher to dispatch incoming events.
      view.renderer.register(view);
    },
    exit: function (view) {
      view.renderer.unregister(view);
    }
  });

  exports.default = inDOM;
});