define('ember-cli-bkf-core/enums/account-type', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.accountType = undefined;
  var accountType = exports.accountType = [{
    text: 'Corrente',
    value: 'checkings'
  }, {
    text: 'Poupança',
    value: 'savings'
  }];

  exports.default = accountType;
  var values = exports.values = _lodash.default.map(accountType, 'value');
});