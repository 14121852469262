define('ember-cli-bkf-core/enums/additional-income-type', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.additionalIncomeType = undefined;
  var additionalIncomeType = exports.additionalIncomeType = [{
    text: 'Locação de bens',
    value: 'rental'
  }, {
    text: 'Prestação de serviços',
    value: 'service_delivery'
  }, {
    text: 'Pensão',
    value: 'pension'
  }, {
    text: 'Empresa(s)',
    value: 'own_company'
  }, {
    text: 'Previdência/Aposentaria',
    value: 'retirement'
  }, {
    text: 'Outros',
    value: 'others'
  }];

  exports.default = additionalIncomeType;
  var values = exports.values = _lodash.default.map(additionalIncomeType, 'value');
});