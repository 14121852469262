define('ember-data-copyable/mixins/copyable', ['exports', 'ember-data-copyable/utils/get-transform', 'ember-data-copyable/utils/is-undefined', 'ember-data-copyable/-private/symbols', 'ember-concurrency'], function (exports, _getTransform, _isUndefined, _symbols, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Ember$Mixin$create;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var assign = Ember.assign,
      Logger = Ember.Logger,
      guidFor = Ember.guidFor,
      isEmpty = Ember.isEmpty,
      runInDebug = Ember.runInDebug;
  var keys = Object.keys;


  var PRIMITIVE_TYPES = ['string', 'number', 'boolean'];

  var DEFAULT_OPTIONS = {
    // List of all attributes to ignore
    ignoreAttributes: [],

    // List of all attributes to copy by reference
    copyByReference: [],

    // Overwrite specific keys with a given value
    overwrite: {},

    // Relationship options
    relationships: {}
  };

  exports.default = Ember.Mixin.create((_Ember$Mixin$create = {
    /**
     * Copyable options for the specific model. See DEFAULT_OPTIONS for details
     *
     * @type {Object}
     * @public
     */
    copyableOptions: null

  }, _defineProperty(_Ember$Mixin$create, _symbols.IS_COPYABLE, true), _defineProperty(_Ember$Mixin$create, 'copy', function copy() /* deep, options */{
    var _get;

    return (_get = this.get(_symbols.COPY_TASK_RUNNER)).perform.apply(_get, arguments);
  }), _defineProperty(_Ember$Mixin$create, _symbols.COPY_TASK_RUNNER, (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(deep, options) {
    var _this = this;

    var _meta, store, isSuccessful, model, copiesKeys;

    return regeneratorRuntime.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            _meta = { copies: {}, transforms: {} };
            store = this.get('store');
            isSuccessful = false;
            _context.prev = 3;
            _context.next = 6;
            return this.get(_symbols.COPY_TASK).perform(deep, options, _meta);

          case 6:
            model = _context.sent;

            isSuccessful = true;

            return _context.abrupt('return', model);

          case 11:
            _context.prev = 11;
            _context.t0 = _context['catch'](3);

            runInDebug(function () {
              return Logger.error('[ember-data-copyable]', _context.t0);
            });

            // Throw so the task promise will be rejected
            throw new Error(_context.t0);

          case 15:
            _context.prev = 15;

            if (!isSuccessful) {
              copiesKeys = keys(_meta.copies);

              // Display the error

              runInDebug(function () {
                return Logger.error('[ember-data-copyable] Failed to copy model \'' + _this + '\'. Cleaning up ' + copiesKeys.length + ' created copies...');
              });

              // Unload all created records
              copiesKeys.forEach(function (key) {
                return store.unloadRecord(_meta.copies[key]);
              });
            }
            return _context.finish(15);

          case 18:
          case 'end':
            return _context.stop();
        }
      }
    }, _callee, this, [[3, 11, 15, 18]]);
  })).drop()), _defineProperty(_Ember$Mixin$create, _symbols.COPY_TASK, (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2(deep, options, _meta) {
    var _this2 = this;

    var _options, ignoreAttributes, copyByReference, overwrite, copies, modelName, store, guid, attrs, model, relationships, i, _relationships$i, name, meta, ref, copyRef, value, firstObject;

    return regeneratorRuntime.wrap(function _callee2$(_context2) {
      while (1) {
        switch (_context2.prev = _context2.next) {
          case 0:
            options = assign({}, DEFAULT_OPTIONS, this.get('copyableOptions'), options);

            _options = options, ignoreAttributes = _options.ignoreAttributes, copyByReference = _options.copyByReference, overwrite = _options.overwrite;
            copies = _meta.copies;
            modelName = this.constructor.modelName;
            store = this.get('store');
            guid = guidFor(this);
            attrs = {};

            // Handle cyclic relationships: If the model has already been copied,
            // just return that model

            if (!copies[guid]) {
              _context2.next = 9;
              break;
            }

            return _context2.abrupt('return', copies[guid]);

          case 9:
            model = store.createRecord(modelName);

            copies[guid] = model;

            // Copy all the attributes
            this.eachAttribute(function (name, _ref) {
              var type = _ref.type,
                  attributeOptions = _ref.options;

              if (ignoreAttributes.includes(name)) {
                return;
              } else if (!(0, _isUndefined.default)(overwrite[name])) {
                attrs[name] = overwrite[name];
              } else if (!isEmpty(type) && !copyByReference.includes(name) && !PRIMITIVE_TYPES.includes(type)) {
                var value = _this2.get(name);
                var transform = (0, _getTransform.default)(_this2, type, _meta);

                // Run the transform on the value. This should guarantee that we get
                // a new instance.
                value = transform.serialize(value, attributeOptions);
                value = transform.deserialize(value, attributeOptions);

                attrs[name] = value;
              } else {
                attrs[name] = _this2.get(name);
              }
            });

            if (!deep) {
              _context2.next = 49;
              break;
            }

            relationships = [];

            // Get all the relationship data

            this.eachRelationship(function (name, meta) {
              if (!ignoreAttributes.includes(name)) {
                relationships.push({ name: name, meta: meta });
              }
            });

            // Copy all the relationships
            i = 0;

          case 16:
            if (!(i < relationships.length)) {
              _context2.next = 49;
              break;
            }

            _relationships$i = relationships[i], name = _relationships$i.name, meta = _relationships$i.meta;

            if ((0, _isUndefined.default)(overwrite[name])) {
              _context2.next = 21;
              break;
            }

            attrs[name] = overwrite[name];
            return _context2.abrupt('continue', 46);

          case 21:
            if (!copyByReference.includes(name)) {
              _context2.next = 24;
              break;
            }

            try {
              ref = this[meta.kind](name);
              copyRef = model[meta.kind](name);

              /*
                NOTE: This is currently private API but has been approved @igorT.
                      Supports Ember Data 2.5+
               */

              if (meta.kind === 'hasMany') {
                copyRef.hasManyRelationship.addRecords(ref.hasManyRelationship.members);
              } else if (meta.kind === 'belongsTo') {
                copyRef.belongsToRelationship.addRecords(ref.belongsToRelationship.members);
              }
            } catch (e) {
              attrs[name] = this.get(name);
            }

            return _context2.abrupt('continue', 46);

          case 24:
            _context2.next = 26;
            return this.get(name);

          case 26:
            value = _context2.sent;

            if (!(meta.kind === 'belongsTo')) {
              _context2.next = 37;
              break;
            }

            if (!(value && value.get(_symbols.IS_COPYABLE))) {
              _context2.next = 34;
              break;
            }

            _context2.next = 31;
            return value.get(_symbols.COPY_TASK).perform(true, options.relationships[name], _meta);

          case 31:
            attrs[name] = _context2.sent;
            _context2.next = 35;
            break;

          case 34:
            attrs[name] = value;

          case 35:
            _context2.next = 46;
            break;

          case 37:
            if (!(meta.kind === 'hasMany')) {
              _context2.next = 46;
              break;
            }

            firstObject = value.get('firstObject');

            if (!(firstObject && firstObject.get(_symbols.IS_COPYABLE))) {
              _context2.next = 45;
              break;
            }

            _context2.next = 42;
            return (0, _emberConcurrency.all)(value.getEach(_symbols.COPY_TASK).invoke('perform', true, options.relationships[name], _meta));

          case 42:
            attrs[name] = _context2.sent;
            _context2.next = 46;
            break;

          case 45:
            attrs[name] = value;

          case 46:
            i++;
            _context2.next = 16;
            break;

          case 49:

            // Since overwrite can include `id` or other properties that have not been touched
            // we want to still include them.
            assign(attrs, overwrite);

            // Set the properties on the model
            model.setProperties(attrs);

            return _context2.abrupt('return', model);

          case 52:
          case 'end':
            return _context2.stop();
        }
      }
    }, _callee2, this);
  }))), _Ember$Mixin$create));
});