define('ember-cli-bkf-core/utils/pusher', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.onEvent = onEvent;
  exports.fromProperty = fromProperty;

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var getOwner = Ember.getOwner,
      on = Ember.on,
      get = Ember.get,
      observer = Ember.observer;
  function onEvent(channelNameProperty, eventNameProperty, fn) {
    var currentSubscription = void 0;

    var channelNameValue = coerceValue(channelNameProperty);
    var eventNameValue = coerceValue(eventNameProperty);

    var watchedProperties = channelNameValue.propertyNames().concat(eventNameValue.propertyNames());
    if (watchedProperties.length > 0) {
      return observer.apply(undefined, _toConsumableArray(watchedProperties).concat([on('init', subscribe)]));
    }

    return on('init', subscribe);

    function subscribe() {
      var _this = this;

      teardownCurrentSubscription();

      var pusherService = getOwner(this).lookup('service:pusher');
      var channelName = channelNameValue.getValue(this);
      var eventName = eventNameValue.getValue(this);

      currentSubscription = pusherService.subscribe(channelName, eventName, this, function () {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        if (get(_this, 'isDestroyed')) {
          return teardownCurrentSubscription();
        }

        return fn.call.apply(fn, [_this].concat(args));
      });
    }

    function teardownCurrentSubscription() {
      if (currentSubscription == null) {
        return;
      }

      currentSubscription.teardown();
      currentSubscription = null;
    }
  }

  var FixedValue = function () {
    _createClass(FixedValue, [{
      key: 'propertyNames',
      value: function propertyNames() {
        return [];
      }
    }, {
      key: 'getValue',
      value: function getValue() /* target */{
        return this.value;
      }
    }]);

    function FixedValue(value) {
      _classCallCheck(this, FixedValue);

      this.value = value;
    }

    return FixedValue;
  }();

  var ValueFromProperty = function () {
    _createClass(ValueFromProperty, [{
      key: 'propertyNames',
      value: function propertyNames() {
        return [this.propertyName];
      }
    }, {
      key: 'getValue',
      value: function getValue(target) {
        return get(target, this.propertyName);
      }
    }]);

    function ValueFromProperty(propertyName) {
      _classCallCheck(this, ValueFromProperty);

      this.propertyName = propertyName;
    }

    return ValueFromProperty;
  }();

  function fromProperty(propertyName) {
    return new ValueFromProperty(propertyName);
  }

  function coerceValue(value) {
    if (value instanceof ValueFromProperty) {
      return value;
    }

    return new FixedValue(value);
  }
});