enifed('ember-views/mixins/view_support', ['exports', 'ember-utils', 'ember-metal', 'ember-debug', 'ember-environment', 'ember-views/system/utils', 'ember-runtime/system/core_object', 'ember-views/system/jquery'], function (exports, _emberUtils, _emberMetal, _emberDebug, _emberEnvironment, _utils, _core_object, _jquery) {
  'use strict';

  var _Mixin$create;

  function K() {
    return this;
  }

  /**
   @class ViewMixin
   @namespace Ember
   @private
  */
  exports.default = _emberMetal.Mixin.create((_Mixin$create = {
    /**
      A list of properties of the view to apply as attributes. If the property
      is a string value, the value of that string will be applied as the value
      for an attribute of the property's name.
       The following example creates a tag like `<div priority="high" />`.
       ```javascript
      Ember.Component.extend({
        attributeBindings: ['priority'],
        priority: 'high'
      });
      ```
       If the value of the property is a Boolean, the attribute is treated as
      an HTML Boolean attribute. It will be present if the property is `true`
      and omitted if the property is `false`.
       The following example creates markup like `<div visible />`.
       ```javascript
      Ember.Component.extend({
        attributeBindings: ['visible'],
        visible: true
      });
      ```
       If you would prefer to use a custom value instead of the property name,
      you can create the same markup as the last example with a binding like
      this:
       ```javascript
      Ember.Component.extend({
        attributeBindings: ['isVisible:visible'],
        isVisible: true
      });
      ```
       This list of attributes is inherited from the component's superclasses,
      as well.
       @property attributeBindings
      @type Array
      @default []
      @public
     */
    concatenatedProperties: ['attributeBindings']

  }, _Mixin$create[_core_object.POST_INIT] = function () {
    this.trigger('didInitAttrs');
    this.trigger('didReceiveAttrs');
  }, _Mixin$create.nearestOfType = function (klass) {
    var view = this.parentView;
    var isOfType = klass instanceof _emberMetal.Mixin ? function (view) {
      return klass.detect(view);
    } : function (view) {
      return klass.detect(view.constructor);
    };

    while (view) {
      if (isOfType(view)) {
        return view;
      }
      view = view.parentView;
    }
  }, _Mixin$create.nearestWithProperty = function (property) {
    var view = this.parentView;

    while (view) {
      if (property in view) {
        return view;
      }
      view = view.parentView;
    }
  }, _Mixin$create.rerender = function () {
    return this._currentState.rerender(this);
  }, _Mixin$create.element = (0, _emberMetal.descriptor)({
    configurable: false,
    enumerable: false,
    get: function () {
      return this.renderer.getElement(this);
    }
  }), _Mixin$create.$ = function (sel) {
    false && !(this.tagName !== '') && (0, _emberDebug.assert)('You cannot access this.$() on a component with `tagName: \'\'` specified.', this.tagName !== '');

    if (this.element) {
      return sel ? (0, _jquery.default)(sel, this.element) : (0, _jquery.default)(this.element);
    }
  }, _Mixin$create.appendTo = function (selector) {
    var env = this._environment || _emberEnvironment.environment;
    var target = void 0;

    if (env.hasDOM) {
      target = typeof selector === 'string' ? document.querySelector(selector) : selector;

      false && !target && (0, _emberDebug.assert)('You tried to append to (' + selector + ') but that isn\'t in the DOM', target);
      false && !!(0, _utils.matches)(target, '.ember-view') && (0, _emberDebug.assert)('You cannot append to an existing Ember.View.', !(0, _utils.matches)(target, '.ember-view'));
      false && !function () {
        var node = target.parentNode;
        while (node) {
          if (node.nodeType !== 9 && (0, _utils.matches)(node, '.ember-view')) {
            return false;
          }

          node = node.parentNode;
        }

        return true;
      }() && (0, _emberDebug.assert)('You cannot append to an existing Ember.View.', function () {
        var node = target.parentNode;while (node) {
          if (node.nodeType !== 9 && (0, _utils.matches)(node, '.ember-view')) {
            return false;
          }node = node.parentNode;
        }return true;
      }());
    } else {
      target = selector;

      false && !(typeof target !== 'string') && (0, _emberDebug.assert)('You tried to append to a selector string (' + selector + ') in an environment without jQuery', typeof target !== 'string');
      false && !(typeof selector.appendChild === 'function') && (0, _emberDebug.assert)('You tried to append to a non-Element (' + selector + ') in an environment without jQuery', typeof selector.appendChild === 'function');
    }

    this.renderer.appendTo(this, target);

    return this;
  }, _Mixin$create.append = function () {
    return this.appendTo(document.body);
  }, _Mixin$create.elementId = null, _Mixin$create.findElementInParentElement = function (parentElem) {
    var id = '#' + this.elementId;
    return (0, _jquery.default)(id)[0] || (0, _jquery.default)(id, parentElem)[0];
  }, _Mixin$create.willInsertElement = K, _Mixin$create.didInsertElement = K, _Mixin$create.willClearRender = K, _Mixin$create.destroy = function () {
    this._super.apply(this, arguments);
    this._currentState.destroy(this);
  }, _Mixin$create.willDestroyElement = K, _Mixin$create.parentViewDidChange = K, _Mixin$create.tagName = null, _Mixin$create.init = function () {
    var owner, dispatcher;

    this._super.apply(this, arguments);

    if (!this.elementId && this.tagName !== '') {
      this.elementId = (0, _emberUtils.guidFor)(this);
    }

    // if we find an `eventManager` property, deopt the
    // `EventDispatcher`'s `canDispatchToEventManager` property
    // if `null`
    if (this.eventManager) {
      owner = (0, _emberUtils.getOwner)(this);
      dispatcher = owner && owner.lookup('event_dispatcher:main');


      false && !false && (0, _emberDebug.deprecate)('`eventManager` has been deprecated in ' + this + '.', false, {
        id: 'ember-views.event-dispatcher.canDispatchToEventManager',
        until: '2.17.0'
      });

      if (dispatcher && !('canDispatchToEventManager' in dispatcher)) {
        dispatcher.canDispatchToEventManager = true;
      }
    }

    false && !(typeof this.didInitAttrs !== 'function') && (0, _emberDebug.deprecate)('[DEPRECATED] didInitAttrs called in ' + this.toString() + '.', typeof this.didInitAttrs !== 'function', {
      id: 'ember-views.did-init-attrs',
      until: '3.0.0',
      url: 'https://emberjs.com/deprecations/v2.x#toc_ember-component-didinitattrs'
    });
    false && !!this.render && (0, _emberDebug.assert)('Using a custom `.render` function is no longer supported.', !this.render);
  }, _Mixin$create.__defineNonEnumerable = function (property) {
    this[property.name] = property.descriptor.value;
  }, _Mixin$create.handleEvent = function (eventName, evt) {
    return this._currentState.handleEvent(this, eventName, evt);
  }, _Mixin$create));
});