define('ember-cli-bkf-core/enums/income-proof', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.incomeProof = undefined;
  var incomeProof = exports.incomeProof = [{
    text: 'Holerite',
    value: 'paycheck'
  }, {
    text: 'Extrato Bancário',
    value: 'bank_statement'
  }, {
    text: 'Imposto de renda',
    value: 'income_tax_return'
  }];

  exports.default = incomeProof;
  var values = exports.values = _lodash.default.map(incomeProof, 'value');
});