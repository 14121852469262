define('ember-cli-hermes/services/hermes-dialer', ['exports', 'fetch', 'ember-cli-hermes/configuration'], function (exports, _fetch2, _configuration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = Ember.Service.extend({
    hermesWorker: Ember.inject.service('hermes-worker'),
    host: Ember.get(_configuration.default, 'host'),
    enableNotifications: false,
    _token: '',

    defineToken: function defineToken(token) {
      this.set('_token', token);
    },
    call: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(origin, product, reference, numberOrigin, numberDestination) {
        var payload, request, response;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                payload = {
                  origin: origin,
                  product: product,
                  reference: reference,
                  number_origin: numberOrigin,
                  number_destination: numberDestination
                };
                _context.next = 3;
                return this._requestDialer(payload);

              case 3:
                request = _context.sent;

                if (!(request.status === 201)) {
                  _context.next = 10;
                  break;
                }

                _context.next = 7;
                return request.json();

              case 7:
                response = _context.sent;


                if (Ember.get(this, 'enableNotifications') && response.voices) {
                  this.get('hermesWorker').connectVoiceChannel(response.voices.id);
                }

                return _context.abrupt('return', response);

              case 10:
                throw new Error('Dialer Failure: ' + request.statusText);

              case 11:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function call(_x, _x2, _x3, _x4, _x5) {
        return _ref.apply(this, arguments);
      }

      return call;
    }(),
    _requestDialer: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(payload) {
        var url;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                url = this.get('host') + '/voices/dialer';
                return _context2.abrupt('return', this._fetch(url, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.get('_token')
                  },
                  body: JSON.stringify(payload)
                }));

              case 2:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function _requestDialer(_x6) {
        return _ref2.apply(this, arguments);
      }

      return _requestDialer;
    }(),
    _fetch: function _fetch(url, params) {
      return (0, _fetch2.default)(url, params);
    }
  });
});