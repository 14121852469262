define('ember-cli-bkf-core/enums/residence-ownership', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.residenceOwnership = undefined;
  var residenceOwnership = exports.residenceOwnership = [{
    text: 'Própria',
    value: 'owned'
  }, {
    text: 'De Familiares',
    value: 'family_owned'
  }, {
    text: 'Alugada',
    value: 'rented'
  }];

  exports.default = residenceOwnership;
  var values = exports.values = _lodash.default.map(residenceOwnership, 'value');
});