(function() {
  function vendorModule() {
    'use strict';

    return {
      'default': self['VMasker'],
      __esModule: true,
    };
  }

  define('vanilla-masker', [], vendorModule);
})();
