define('ember-cli-bkf-core/services/blob-download', ['exports', 'fetch'], function (exports, _fetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = Ember.Service.extend({
    session: Ember.inject.service('session'),

    status: null,

    isDownloading: Ember.computed.equal('status', 'downloading'),

    isError: Ember.computed.equal('status', 'error'),

    downloadFile: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(url, fileName) {
        var response, blob;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                if (!this.get('isDownloading')) {
                  _context.next = 2;
                  break;
                }

                return _context.abrupt('return');

              case 2:

                this.set('status', 'downloading');

                _context.next = 5;
                return this._fetchBlob(url);

              case 5:
                response = _context.sent;

                if (!(response.status !== 200)) {
                  _context.next = 9;
                  break;
                }

                this.set('status', 'error');

                return _context.abrupt('return');

              case 9:
                _context.next = 11;
                return response.blob();

              case 11:
                blob = _context.sent;


                this._downloadBlob(blob, fileName || response.headers.get('x-bkf-filename'));
                this.set('status', null);

              case 14:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function downloadFile(_x, _x2) {
        return _ref.apply(this, arguments);
      }

      return downloadFile;
    }(),
    _fetchBlob: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(url) {
        var accessToken, adapter, host, headers;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                accessToken = this.get('session.data.authenticated.access_token');
                adapter = Ember.getOwner(this).lookup('adapter:application');
                host = Ember.get(adapter, 'host');
                headers = {
                  'accept-version': 'v1',
                  'authorization': 'Bearer ' + accessToken
                };
                return _context2.abrupt('return', (0, _fetch.default)('' + host + url, { headers: headers }));

              case 5:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function _fetchBlob(_x3) {
        return _ref2.apply(this, arguments);
      }

      return _fetchBlob;
    }(),
    _downloadBlob: function _downloadBlob(blob, fileName) {
      var fakeLink = document.createElement('a');

      if (window.navigator.msSaveOrOpenBlob) {
        fakeLink.onclick = function () {
          return window.navigator.msSaveOrOpenBlob(blob, fileName);
        };
      } else {
        fakeLink.download = fileName;
        fakeLink.href = window.URL.createObjectURL(blob);
      }

      fakeLink.click();
    }
  });
});