enifed('ember-glimmer/utils/curly-component-state-bucket', ['exports'], function (exports) {
  'use strict';

  function NOOP() {}

  /**
    @module ember
    @submodule ember-glimmer
  */

  /**
    Represents the internal state of the component.
  
    @class ComponentStateBucket
    @private
  */

  var ComponentStateBucket = function () {
    function ComponentStateBucket(environment, component, args, finalizer) {

      this.environment = environment;
      this.component = component;
      this.classRef = null;
      this.args = args;
      this.argsRevision = args.tag.value();
      this.finalizer = finalizer;
    }

    ComponentStateBucket.prototype.destroy = function () {
      var component = this.component,
          environment = this.environment;

      if (environment.isInteractive) {
        component.trigger('willDestroyElement');
        component.trigger('willClearRender');
      }

      environment.destroyedComponents.push(component);
    };

    ComponentStateBucket.prototype.finalize = function () {
      var finalizer = this.finalizer;

      finalizer();
      this.finalizer = NOOP;
    };

    return ComponentStateBucket;
  }();

  exports.default = ComponentStateBucket;
});