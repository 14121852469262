define('ember-cli-bkf-core/models/bacen-authorizations/santana/credit-check', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    authorized: _emberData.default.attr('boolean'),
    authorizedAt: _emberData.default.attr('date'),
    attachment: _emberData.default.attr(),
    clicksign: _emberData.default.attr()
  });
});