define('ember-cli-bkf-core/enums/real-estate-usage', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.realEstateUsage = undefined;
  var realEstateUsage = exports.realEstateUsage = [{
    text: 'Residencial',
    value: 'residential'
  }, {
    text: 'Casa de verão',
    value: 'summer_house'
  }, {
    text: 'Aluguel',
    value: 'rent'
  }];

  exports.default = realEstateUsage;
  var values = exports.values = _lodash.default.map(realEstateUsage, 'value');
});