define('ember-cli-bkf-core/models/crm/customer', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'email',
      min: 6,
      max: 100
    })],

    password: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 6,
      max: 255
    })],

    cpf: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 11
    })],

    birthDate: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('date', {
      before: 'now',
      errorFormat: 'DD/MM/YYYY'
    })],

    fullName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 5,
      max: 70
    })],

    contactResidentialTelephoneCode: (0, _emberCpValidations.validator)('length', {
      is: 2
    }),

    contactResidentialTelephone: (0, _emberCpValidations.validator)('length', {
      min: 8, max: 9
    }),

    contactCellphoneCode: (0, _emberCpValidations.validator)('length', {
      is: 2,
      allowBlank: true
    }),

    contactCellphone: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 8, max: 9
    })],

    contactAdditionalPhoneCode: (0, _emberCpValidations.validator)('length', {
      is: 2,
      allowBlank: true
    }),

    contactAdditionalPhone: [(0, _emberCpValidations.validator)('length', {
      min: 8, max: 9,
      allowBlank: true
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    activeApplication: _emberData.default.belongsTo('loan/home-refi/bkf/application'),
    autoRefinancingLeads: _emberData.default.hasMany('crm/auto-refi/lead'),

    cpf: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    fullName: _emberData.default.attr('string'),
    birthDate: _emberData.default.attr('pure-date'),
    contactResidentialTelephoneCode: _emberData.default.attr('string'),
    contactResidentialTelephone: _emberData.default.attr('string'),
    contactCellphoneCode: _emberData.default.attr('string'),
    contactCellphone: _emberData.default.attr('string'),
    contactAdditionalPhoneCode: _emberData.default.attr('string'),
    contactAdditionalPhone: _emberData.default.attr('string'),
    allowSms: _emberData.default.attr('boolean', { defaultValue: true }),

    password: _emberData.default.attr('string'),
    activeHomeRefiLead: _emberData.default.belongsTo('crm/home-refi/lead'),
    activeAutoRefiLead: _emberData.default.belongsTo('crm/auto-refi/lead')
  });
});