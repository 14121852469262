define('ember-cli-bkf-core/models/loan/auto-refi/bkf/simulation', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    application: (0, _relationships.belongsTo)('loan/auto-refi/bkf/application'),

    createdAt: (0, _attr.default)('date'),
    installmentAmount: (0, _attr.default)('number'),
    interestRate: (0, _attr.default)('number'),
    adjustedAmount: (0, _attr.default)('number'),
    financingTimeMonths: (0, _attr.default)('number'),
    calculationMethod: (0, _attr.default)('string'),
    calculatorVersion: (0, _attr.default)('string'),
    uf: (0, _attr.default)('string'),
    carValue: (0, _attr.default)('number'),
    carAge: (0, _attr.default)('number'),
    creditScore: (0, _attr.default)('number'),
    monthlyIncome: (0, _attr.default)('number'),
    requestedAmount: (0, _attr.default)('number')
  });
});