define('ember-cli-bkf-core/models/loan/home-refi/bkf/person', ['exports', 'ember-data', 'ember-cp-validations', 'ember-cli-bkf-core/enums'], function (exports, _emberData, _emberCpValidations, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    fullName: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 5,
      max: 70
    }),

    motherFullName: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 5,
      max: 70
    }),

    fatherFullName: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 5,
      max: 70
    }),

    gender: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.genderValues
    }),

    cpf: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      is: 11
    }),

    children: (0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      gte: 0,
      lte: 25
    }),

    documentType: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.documentTypeValues
    }),

    documentNumber: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 6,
      max: 15
    }),

    documentIssuer: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 3,
      max: 9
    }),

    cityOfBirth: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 2,
      max: 40
    }),

    stateOfBirth: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      min: 2,
      max: 40
    }),

    nationality: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 5,
      max: 30
    }),

    maritalStatus: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.maritalStatusValues
    }),

    regimen: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.marriageRegimenValues
    }),

    personalReferenceName: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 5,
      max: 48
    }),

    personalReferencePhone: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 8,
      max: 9
    }),

    personalReferencePhoneCode: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      is: 2
    }),

    monthlyIncome: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      gte: 0
    }),

    incomeProof: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.incomeProofValues
    }),

    retirementNumber: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 4,
      max: 20
    }),

    additionalIncomeType: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.incomeTypeValues
    }),

    additionalIncomeDescription: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 4,
      max: 70
    }),

    contactEmail: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 6,
      max: 100
    }),

    contactResidentialTelephoneCode: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      is: 2
    }),

    contactResidentialTelephone: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 8,
      max: 9
    }),

    contactCellphoneCode: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      is: 2
    }),

    contactCellphone: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 8,
      max: 9
    }),

    contactProfessionalEmail: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 6,
      max: 100
    }),

    contactProfessionalTelephoneCode: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      is: 2
    }),

    contactProfessionalTelephone: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 8,
      max: 9
    }),

    financeDebtDescription: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      max: 80
    }),

    financeDebtMonthsLeft: (0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      gte: 1,
      lte: 360
    }),

    professionalReferenceName: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 5,
      max: 48
    }),

    professionalReferencePhone: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 8,
      max: 9
    }),

    professionalReferencePhoneCode: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      is: 2
    }),

    financeBankNumber: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 1,
      max: 4
    }),

    financeBankAgencyNumber: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 3,
      max: 7
    }),

    financeBankAccountNumber: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 3,
      max: 15
    }),

    financeBankAccountType: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.accountTypeValues
    })
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    spouse: _emberData.default.belongsTo('loan/home-refi/bkf/person', { inverseOf: 'spouse' }),
    residenceAddress: _emberData.default.belongsTo('loan/home-refi/bkf/address'),
    mailingAddress: _emberData.default.belongsTo('loan/home-refi/bkf/address'),

    currentEmployment: _emberData.default.belongsTo('loan/home-refi/bkf/employment'),
    previousEmployment: _emberData.default.belongsTo('loan/home-refi/bkf/employment'),
    additionalPreviousEmployment: _emberData.default.belongsTo('loan/home-refi/bkf/employment'),

    fullName: _emberData.default.attr('string'),
    motherFullName: _emberData.default.attr('string'),
    fatherFullName: _emberData.default.attr('string'),
    politicallyExposedPerson: _emberData.default.attr('boolean'),
    cpf: _emberData.default.attr('string'),
    gender: _emberData.default.attr('string'),
    children: _emberData.default.attr('number'),
    birthDate: _emberData.default.attr('pure-date'),
    documentType: _emberData.default.attr('string', { defaultValue: 'rg' }),
    documentNumber: _emberData.default.attr('string'),
    documentIssuer: _emberData.default.attr('string'),
    documentExpeditionDate: _emberData.default.attr('pure-date'),
    cityOfBirth: _emberData.default.attr('string'),
    stateOfBirth: _emberData.default.attr('string'),
    nationality: _emberData.default.attr('string'),
    maritalStatus: _emberData.default.attr('string'),
    regimen: _emberData.default.attr('string'),
    stableUnion: _emberData.default.attr(),
    personalReferenceName: _emberData.default.attr('string'),
    personalReferencePhone: _emberData.default.attr('string'),
    personalReferencePhoneCode: _emberData.default.attr('string'),
    monthlyIncome: _emberData.default.attr('number'),
    incomeProof: _emberData.default.attr('string'),
    retirementNumber: _emberData.default.attr('string'),
    educationDegree: _emberData.default.attr('string'),

    additionalIncomeValue: _emberData.default.attr('number'),
    additionalIncomeType: _emberData.default.attr('string'),
    additionalIncomeDescription: _emberData.default.attr('string'),

    // Contact Data
    contactEmail: _emberData.default.attr('string'),
    contactResidentialTelephone: _emberData.default.attr('string'),
    contactResidentialTelephoneCode: _emberData.default.attr('string'),
    contactProfessionalEmail: _emberData.default.attr('string'),
    contactProfessionalTelephone: _emberData.default.attr('string'),
    contactProfessionalTelephoneCode: _emberData.default.attr('string'),
    contactCellphone: _emberData.default.attr('string'),
    contactCellphoneCode: _emberData.default.attr('string'),

    // Financial Data
    financeBankNumber: _emberData.default.attr('string'),
    financeBankAgencyNumber: _emberData.default.attr('string'),
    financeBankAccountNumber: _emberData.default.attr('string'),
    financeBankAccountType: _emberData.default.attr('string'),
    financeBankAccountOwnerName: _emberData.default.attr('string'),
    financeDebtDescription: _emberData.default.attr('string'),
    financeDebtMonthlyCost: _emberData.default.attr('number'),
    financeDebtMonthsLeft: _emberData.default.attr('number'),

    // Professional Data
    professionalReferenceName: _emberData.default.attr('string'),
    professionalReferencePhone: _emberData.default.attr('string'),
    professionalReferencePhoneCode: _emberData.default.attr('string')
  });
});