define('ember-cli-bkf-core/enums/monetary-adjustment-index', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.monetaryAdjustmentIndex = undefined;
  var monetaryAdjustmentIndex = exports.monetaryAdjustmentIndex = [{
    'text': 'IPCA',
    'value': 'ipca'
  }, {
    'text': 'Sem Indexador',
    'value': 'without_monetary_adjustment_index'
  }];

  exports.default = monetaryAdjustmentIndex;
  var values = exports.values = _lodash.default.map(monetaryAdjustmentIndex, 'value');
});