define('ember-cli-bkf-core/enums/gender', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.gender = undefined;
  var gender = exports.gender = [{
    text: 'Masculino',
    value: 'male'
  }, {
    text: 'Feminino',
    value: 'female'
  }];

  exports.default = gender;
  var values = exports.values = _lodash.default.map(gender, 'value');
});