define('ember-data-copyable/utils/get-transform', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getTransform;
  var getOwner = Ember.getOwner;


  /**
   * Get the transform for a given type. Uses the private `_meta` cache.
   *
   * @method getTransform
   * @private
   * @param  {DS.Model} model
   * @param  {String} type
   * @param  {Object} _meta Copy task meta object
   * @return {DS.Transform}
   */
  function getTransform(model, type, _meta) {
    _meta.transforms[type] = _meta.transforms[type] || getOwner(model).lookup('transform:' + type);
    return _meta.transforms[type];
  }
});