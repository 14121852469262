define('ember-cli-bkf-core/models/signatures/digital/document', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    documentKey: _emberData.default.attr('string'),
    signatures: _emberData.default.attr(),
    completelySignedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date')
  });
});