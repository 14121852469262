define('ember-cli-bkf-core/models/loan/auto-refi/bkf/document', ['exports', 'ember-data', 'ember-data/relationships'], function (exports, _emberData, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    application: (0, _relationships.belongsTo)('loan/auto-refi/bkf/application'),
    person: (0, _relationships.belongsTo)('loan/auto-refi/bkf/person'),
    attachments: (0, _relationships.hasMany)('loan/auto-refi/bkf/attachment'),

    name: _emberData.default.attr('string'),
    uploadUrl: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    status: _emberData.default.attr('string')
  });
});