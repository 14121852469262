define('ember-cli-bkf-core/enums/marriage-regimen', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.marriageRegimen = undefined;
  var marriageRegimen = exports.marriageRegimen = [{
    text: 'Comunhão universal de bens',
    value: 'full'
  }, {
    text: 'Comunhão parcial de bens',
    value: 'partial'
  }, {
    text: 'Separação total be bens',
    value: 'separation'
  }];

  exports.default = marriageRegimen;
  var values = exports.values = _lodash.default.map(marriageRegimen, 'value');
});