define('ember-cli-bkf-core/models/underwriting/auto-refi/fidc/application', ['exports', 'ember-data', 'ember-cli-bkf-core/models/underwriting/auto-refi/partner-application-mixin'], function (exports, _emberData, _partnerApplicationMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_partnerApplicationMixin.default, {
    name: 'FIDC',

    calculator: _emberData.default.belongsTo('calculator/auto-refi/operation')
  });
});