define("ember-mockdate-shim/index", ["exports"], function (exports) {
  /* globals MockDate */

  var _ref = MockDate || {
    set: function set() {},
    reset: function reset() {}
  };

  var set = _ref.set;
  var reset = _ref.reset;
  exports.freezeDateAt = set;
  exports.unfreezeDate = reset;
});