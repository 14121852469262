define('ember-cli-hermes/service-worker', ['exports', 'ember-cli-hermes/services/hermes-worker', 'ember-cli-hermes/message-request-permission', 'ember-cli-hermes/message-request-permission-blocked'], function (exports, _hermesWorker, _messageRequestPermission, _messageRequestPermissionBlocked) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = initializeServiceWorker;
  function initializeServiceWorker(application, config) {
    var service = _hermesWorker.default.create({});
    service.set('config', config);
    application.register('services:hermes-worker', service, { singleton: true });

    if (Notification.permission === 'default') {
      var button = (0, _messageRequestPermission.default)();

      document.body.insertBefore(button, document.body.firstChild);

      button.addEventListener('click', function () {
        Notification.requestPermission().then(function (result) {
          button.remove();

          if (result === 'granted') {
            service.register();
          } else {
            var message = (0, _messageRequestPermissionBlocked.default)();
            document.body.insertBefore(message, document.body.firstChild);
          }
        });
      });
    } else if (Notification.permission === 'denied') {
      var message = (0, _messageRequestPermissionBlocked.default)();
      document.body.insertBefore(message, document.body.firstChild);
    } else {
      service.register();
    }
  } /* eslint-disable: no-console */
});