define('ember-cli-bkf-core/locales/pt/translations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = {
    errors: {
      accepted: 'Aceite antes de continuar',
      after: 'Valor inválido (deve ser após {{after}})',
      before: 'Valor inválido (deve ser anterior a {{before}})',
      blank: 'Preencha esse campo para continuar',
      collection: 'Confirme os valores antes de continuar',
      confirmation: 'Campos informados não coincidem',
      date: 'Insira uma data válida para continuar',
      email: 'Insira um e-mail válido para continuar',
      empty: 'Preencha esse campo para continuar',
      equalTo: 'Valor inválido (precisa ser igual a {{is}})',
      even: 'Valor inválido (deve ser um número par)',
      exclusion: 'Valor inválido',
      greaterThan: 'Valor inválido (deve ser maior que {{gt}})',
      greaterThanOrEqualTo: 'Valor inválido (deve ser maior ou igual a {{gte}})',
      inclusion: 'Valor não está incluso na lista',
      invalid: 'Valor inválido',
      lessThan: 'Valor inválido (deve ser menor que {{lt}})',
      lessThanOrEqualTo: 'Valor inválido (deve ser menor ou igual a {{lte}})',
      notANumber: 'Valor inválido (deve ser um número)',
      notAnInteger: 'Número inválido (deve ser um número inteiro)',
      odd: 'Valor inválido (deve ser ímpar)',
      otherThan: 'Valor inválido (deve ser diferente de {{count}})',
      phone: 'Insira um telefone válido para continuar',
      positive: 'Valor inválido (deve ser positivo)',
      present: 'Valor inválido (deve estar em branco)',
      singular: 'Valor inválido (não pode ser um conjunto)',
      tooLong: 'Valor muito longo (utilize no máximo {{max}} caracteres)',
      tooShort: 'Valor muito curto (utilize no mínimo {{min}} caracteres)',
      url: 'Insira uma URL válida para continuar',
      wrongDateFormat: 'Formato inválido (deve estar no formato de {{format}})',
      wrongLength: 'Tamanho errado (deve ter {{is}} caracteres)',
      personalData: 'Dados pessoais inválidos',
      realEstate: 'Dados do imóvel inválidos',
      customerSession: 'Senha ou confirmação de senha inválidos',
      cpf: 'Insira um CPF válido',
      birthDate: 'Insira uma data para continuar'
    },

    backendErrors: {
      size: function size(_ref) {
        var expected = _ref.expected;

        if ((typeof expected === 'undefined' ? 'undefined' : _typeof(expected)) === 'object') {
          return 'Tamanho inv\xE1lido - deve estar entre ' + expected.min + ' e ' + expected.max + ' caracteres';
        }

        return 'Tamanho inv\xE1lido - deve ser ' + expected;
      },
      inclusion: function inclusion(_ref2) {
        var expected = _ref2.expected;

        if (Array.isArray(expected)) {
          return 'N\xE3o est\xE1 inclu\xEDdo na lista: ' + expected;
        }

        return 'Deve estar entre ' + expected.min + ' e ' + expected.max;
      },
      presence: function presence() {
        return 'N\xE3o pode ficar vazio';
      }
    }
  };
});