define('ember-cli-hermes/message-request-permission', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    var el = document.createElement('DIV');
    el.style.cssText = STYLES.join(';');
    el.className = 'ui message blue';
    el.innerHTML = 'O MDO precisa de permissão para enviar notificações para você. <button type="button" class="ui button blue">Habilitar notificações</button>';

    return el;
  };

  var STYLES = ['border:none', 'border-radius: 0', 'box-shadow: none', 'background-color: #2d9ee0', 'color: #FFF', 'text-align: center', 'font-weight: 700', 'cursor: pointer', 'font-size: 14px'];
});