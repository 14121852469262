define('ember-cli-bkf-core/enums/education-degree', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.educationDegree = undefined;
  var educationDegree = exports.educationDegree = [{
    text: 'Sem estudo',
    value: 'illiterate'
  }, {
    text: 'Ensino fundamental incompleto',
    value: 'primary_incomplete'
  }, {
    text: 'Ensino fundamental completo',
    value: 'primary_full'
  }, {
    text: 'Ensino médio incompleto',
    value: 'secondary_incomplete'
  }, {
    text: 'Ensino médio completo',
    value: 'secondary_full'
  }, {
    text: 'Ensino superior incompleto',
    value: 'tertiary_incomplete'
  }, {
    text: 'Ensino superior completo',
    value: 'tertiary_full'
  }, {
    text: 'Pós-graduação',
    value: 'graduate'
  }, {
    text: 'Mestrado',
    value: 'master'
  }, {
    text: 'Doutorado',
    value: 'doctorate'
  }];

  exports.default = educationDegree;
  var values = exports.values = _lodash.default.map(educationDegree, 'value');
});