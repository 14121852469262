define('ember-cli-bkf-core/transforms/boolean-with-null', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    _isBoolean: function _isBoolean(value) {
      return this._isType(value, 'boolean');
    },
    _isNull: function _isNull(value) {
      return this._isType(value, 'null');
    },
    _isType: function _isType(value, type) {
      return Ember.typeOf(value) === type;
    },
    _isFalseString: function _isFalseString(deserialized) {
      return this._isType(deserialized, 'string') && deserialized.match(/^false$/) !== null;
    },
    deserialize: function deserialize(serialized) {
      if (this._isBoolean(serialized) || this._isNull(serialized)) {
        return serialized;
      }

      return true;
    },
    serialize: function serialize(deserialized) {
      if (this._isFalseString(deserialized)) {
        return false;
      }

      if (this._isNull(deserialized)) {
        return deserialized;
      }

      return Boolean(deserialized);
    }
  });
});