define('ember-cli-bkf-core/enums/document-type', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.documentType = undefined;
  var documentType = exports.documentType = [{
    text: 'RG',
    value: 'rg'
  }, {
    text: 'RNE',
    value: 'rne'
  }, {
    text: 'CNH',
    value: 'cnh'
  }];

  exports.default = documentType;
  var values = exports.values = _lodash.default.map(documentType, 'value');
});