define('ember-cli-bkf-core/headers-container', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.buildHeadersContainer = buildHeadersContainer;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var capitalize = Ember.String.capitalize;


  /**
   * Usage example:
   *
   * const Headers = buildHeadersContainer({
   *   'x-bkf-visit-code': {
   *     property: 'visitCode',
   *     resetOnNull: false,
   *   },
   * });
   *
   * export default Ember.Service.extend(Headers);
   **/
  function buildHeadersContainer(config) {
    var mixinProps = {
      _headers: Ember.computed(function () {
        return {};
      }),

      headers: Ember.computed('_headers', function () {
        return _lodash.default.clone(this.get('_headers'));
      })
    };

    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = Object.entries(config)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var _ref = _step.value;

        var _ref2 = _slicedToArray(_ref, 2);

        var headerName = _ref2[0];
        var _ref2$ = _ref2[1];
        var resetOnNull = _ref2$.resetOnNull;
        var property = _ref2$.property;

        var methodName = 'add' + capitalize(property);

        mixinProps[property] = Ember.computed.reads('_headers.' + headerName);
        mixinProps[methodName] = _lodash.default.partial(setHeader, headerName, resetOnNull);
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return Ember.Mixin.create(mixinProps);
  }

  function setHeader(headerName, resetOnNull, value) {
    var newHeaders = _lodash.default.clone(this.get('_headers'));

    if (value != null) {
      newHeaders[headerName] = value;
    } else if (resetOnNull && newHeaders.hasOwnProperty(headerName)) {
      delete newHeaders[headerName];
    }

    this.set('_headers', newHeaders);
  }
});