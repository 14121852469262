define('ember-cli-bkf-core/adapters/bkf-core', ['exports', 'ember-data', 'ember-cli-bkf-core/configuration'], function (exports, _emberData, _configuration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.FOR_UPDATE = undefined;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  /**
   * This symbol is used as a way to mark snapshots that are used for record updates. It is imported
   * by the application serializer.
   **/
  var FOR_UPDATE = exports.FOR_UPDATE = Symbol('update request marker');

  exports.default = _emberData.default.JSONAPIAdapter.extend({
    host: _configuration.default.apiHost,
    contextHeaders: Ember.inject.service(),
    recordSelfLink: Ember.inject.service(),

    headers: Ember.computed('contextHeaders.headers', function () {
      var headers = { 'Accept-Version': 'v1' };
      var serviceHeaders = this.get('contextHeaders.headers') || {};

      var headerNames = Object.keys(serviceHeaders);
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = headerNames[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var headerName = _step.value;

          headers[headerName] = serviceHeaders[headerName];
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return headers;
    }),

    /**
     * Customize the default `updateRecord` to set the `FOR_UPDATE` symbol on the serializer. This is
     * necessary for implementing partial PATCH, since otherwise the serializer doesn't know that the
     * serialization is for a record update, and thus will always add all the attributes.
     *
     * In the long run we probably should adopt whatever comes out of
     * https://github.com/emberjs/data/issues/3467
     **/
    updateRecord: function updateRecord(store, type, snapshot) {
      // This is read by the serializer's `serializeAttribute`
      Object.defineProperty(snapshot, FOR_UPDATE, { value: true });

      return this._super.apply(this, arguments);
    },


    /**
     * Returns the url to be used on patch requests
     **/
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName) {
      var selfLink = this.get('recordSelfLink').getSelfLink({ id: id, modelName: modelName });

      if (selfLink) {
        var apiHost = this.buildURL();

        return '' + apiHost + selfLink;
      }

      for (var _len = arguments.length, args = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      return this._super.apply(this, [id, modelName].concat(_toConsumableArray(args)));
    }
  });
});