define('ember-cli-bkf-core/models/underwriting/home-refi/fapa/application', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    realEstateRegistrationNumber: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      max: 20
    }),

    realEstateRgi: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      max: 20
    }),

    politicallyExposedPersonReason: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      max: 255
    }),

    relatedPublicAgentOrPepName: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      max: 200
    }),

    relatedPublicAgentOrPepCpf: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      is: 11
    }),

    multipleCitizenshipReason: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      max: 255
    }),

    addressAbroadCountry: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      max: 50
    }),

    addressAbroadPhone: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      max: 20
    }),

    addressAbroadFullAddress: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      max: 200
    }),

    permanentResidencyCountry: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      max: 50
    }),

    socialSecurityNumber: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      is: 9
    }),

    abroadCountry: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      max: 50
    }),

    renouncedCitizenshipReason: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      max: 255
    }),

    shareholdingCompanyName: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      max: 200
    }),

    shareholdingCompanyTaxpayerRegistry: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      is: 14
    }),

    shareholdingCompanyNationality: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      max: 50
    }),

    shareholdingPercentage: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      allowBlank: true,
      lte: 100
    }),

    additionalShareholdingCompanyName: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      max: 200
    }),

    additionalShareholdingCompanyTaxpayerRegistry: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      is: 14
    }),

    additionalShareholdingCompanyNationality: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      max: 50
    }),

    additionalShareholdingPercentage: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      allowBlank: true,
      lte: 100
    })
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    bkfApplication: _emberData.default.belongsTo('loan/home-refi/bkf/application'),
    requester: _emberData.default.belongsTo('loan/home-refi/bkf/person'),

    realEstateRegistrationNumber: _emberData.default.attr('string'),
    realEstateRgi: _emberData.default.attr('string'),
    politicallyExposedPersonReason: _emberData.default.attr('string'),
    publicAgent: _emberData.default.attr('boolean', { defaultValue: false }),
    relatedPublicAgentOrPep: _emberData.default.attr('boolean', { defaultValue: false }),
    relatedPublicAgentOrPepName: _emberData.default.attr('string'),
    relatedPublicAgentOrPepCpf: _emberData.default.attr('string'),
    multipleCitizenship: _emberData.default.attr('boolean', { defaultValue: false }),
    multipleCitizenshipReason: _emberData.default.attr('string'),
    addressAbroad: _emberData.default.attr('boolean', { defaultValue: false }),
    addressAbroadCountry: _emberData.default.attr('string'),
    addressAbroadPhone: _emberData.default.attr('string'),
    addressAbroadFullAddress: _emberData.default.attr('string'),
    permanentResidency: _emberData.default.attr('boolean', { defaultValue: false }),
    permanentResidencyCountry: _emberData.default.attr('string'),
    socialSecurityNumber: _emberData.default.attr('string'),
    abroadOneMonthForTheLastYear: _emberData.default.attr('boolean', { defaultValue: false }),
    abroadCountry: _emberData.default.attr('string'),
    usaAbroadDaysCurrentYear: _emberData.default.attr('number'),
    usaAbroadDaysFirstYearPriorToCurrent: _emberData.default.attr('number'),
    usaAbroadDaysSecondYearPriorToCurrent: _emberData.default.attr('number'),
    renouncedCitizenship: _emberData.default.attr('boolean', { defaultValue: false }),
    renouncedCitizenshipReason: _emberData.default.attr('string'),
    shareholdingCompanyName: _emberData.default.attr('string'),
    shareholdingCompanyTaxpayerRegistry: _emberData.default.attr('string'),
    shareholdingCompanyNationality: _emberData.default.attr('string'),
    shareholdingPercentage: _emberData.default.attr('number'),
    additionalShareholdingCompanyName: _emberData.default.attr('string'),
    additionalShareholdingCompanyTaxpayerRegistry: _emberData.default.attr('string'),
    additionalShareholdingCompanyNationality: _emberData.default.attr('string'),
    additionalShareholdingPercentage: _emberData.default.attr('number'),
    binaryDocumentUrl: _emberData.default.attr('string')
  });
});