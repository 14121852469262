define('ember-cli-bkf-core/models/underwriting/home-refi/banco-paulista/application', ['exports', 'ember-data', 'ember-cp-validations', 'ember-cli-bkf-core/enums/amortization-system', 'ember-cli-bkf-core/enums/purpose'], function (exports, _emberData, _emberCpValidations, _amortizationSystem, _purpose) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    amortizationSystem: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _amortizationSystem.values
    }),

    purpose: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _purpose.values
    })
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    bkfApplication: _emberData.default.belongsTo('loan/home-refi/bkf/application'),
    requester: _emberData.default.belongsTo('loan/home-refi/bkf/person'),
    additionalRequester: _emberData.default.belongsTo('loan/home-refi/bkf/person'),

    intendedCredit: _emberData.default.attr('number'),
    financingTimeMonths: _emberData.default.attr('number'),
    amortizationSystem: _emberData.default.attr('string'),
    purpose: _emberData.default.attr('string'),
    financingContractTax: _emberData.default.attr('number')
  });
});