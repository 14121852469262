define('ember-cli-bkf-core/transforms/pure-date', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (!serialized) {
        return null;
      }

      var date = (0, _moment.default)(serialized).toDate();

      return date;
    },
    serialize: function serialize(deserialized) {
      var parsedISODate = (0, _moment.default)(deserialized).format('YYYY-MM-DD');

      return parsedISODate;
    }
  });
});