define('ember-cli-bkf-core/enums/activity-field', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.activityField = undefined;
  var activityField = exports.activityField = [{
    text: 'Artes e entretenimento',
    value: 'arts_and_entertainment'
  }, {
    text: 'Automóveis e veículos',
    value: 'autos_and_vehicles'
  }, {
    text: 'Condicionamento físico e beleza',
    value: 'beauty_and_fitness'
  }, {
    text: 'Livros e literatura',
    value: 'books_and_literature'
  }, {
    text: 'Comercial e industrial',
    value: 'business_and_industrial'
  }, {
    text: 'Computadores e aparelhos eletrônicos',
    value: 'computer_and_electronics'
  }, {
    text: 'Finanças',
    value: 'finance'
  }, {
    text: 'Comida e bebida',
    value: 'food_and_drink'
  }, {
    text: 'Jogos',
    value: 'games'
  }, {
    text: 'Hobbies e lazer',
    value: 'hobbies_and_leisure'
  }, {
    text: 'Casa e jardim',
    value: 'home_and_garden'
  }, {
    text: 'Internet e telecomunicações',
    value: 'internet_and_telecom'
  }, {
    text: 'Empregos e educação',
    value: 'jobs_and_education'
  }, {
    text: 'Lei e governo',
    value: 'law_and_government'
  }, {
    text: 'Notícias',
    value: 'news'
  }, {
    text: 'Comunidades on-line',
    value: 'online_communities'
  }, {
    text: 'Pessoas e sociedade',
    value: 'people_and_society'
  }, {
    text: 'Animais de estimação e animais',
    value: 'pets_and_animals'
  }, {
    text: 'Imóveis',
    value: 'real_estate'
  }, {
    text: 'Referência',
    value: 'reference'
  }, {
    text: 'Ciência',
    value: 'science'
  }, {
    text: 'Compras',
    value: 'shopping'
  }, {
    text: 'Esportes',
    value: 'sports'
  }, {
    text: 'Viagens',
    value: 'travel'
  }, {
    text: 'Localidades mundiais',
    value: 'world_localities'
  }];

  exports.default = activityField;
  var values = exports.values = _lodash.default.map(activityField, 'value');
});