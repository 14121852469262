define('ember-cli-bkf-core/enums/checklist-document', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.personLegalDocuments = exports.applicationLegalDocuments = exports.creditAnalysisDocuments = exports.personalDocuments = exports.realEstateDocuments = exports.formDocuments = undefined;
  var formDocuments = exports.formDocuments = [{
    text: 'Creditas',
    value: 'creditas_form'
  }, {
    text: 'BV',
    value: 'bv_form'
  }, {
    text: 'Máxima',
    value: 'maxima_form'
  }, {
    text: 'Novi',
    value: 'novi_form'
  }];

  var realEstateDocuments = exports.realEstateDocuments = [{
    text: 'Certidões do Imóvel',
    value: 'real_estate_certificates'
  }, {
    text: 'Matrícula do imóvel dado como garantia',
    value: 'real_estate_registration'
  }, {
    text: 'Certidão negativa de débitos de IPTU',
    value: 'real_estate_taxes_discharge_certificate'
  }, {
    text: 'Capa do IPTU',
    value: 'iptu'
  }, {
    text: 'Protocolo RGI',
    value: 'rgi_protocol'
  }, {
    text: 'Laudo',
    value: 'real_estate_appraisal'
  }, {
    text: 'Foto do Imóvel',
    value: 'real_estate_picture',
    description: 'Foto atualizada da frente de sua casa ou prédio'
  }, {
    text: 'Declaração negativa de débitos de condomínio',
    value: 'condominium_debts_absense_declaration'
  }, {
    text: 'Ata de assembléia que elegeu o síndico',
    value: 'assembly_minute'
  }, {
    text: 'Contrato de prestação de serviços da administradora',
    value: 'admin_service_contract'
  }, {
    text: 'Outros documentos',
    value: 'other_real_estate_documents'
  }];

  var personalDocuments = exports.personalDocuments = [{
    text: 'Certidões Pessoais',
    value: 'personal_certificates'
  }, {
    text: 'RG, CNH, RNE ou documento da classe',
    value: 'identity_document',
    description: 'RG ou CNH (frente e verso). Caso o número do CPF não esteja presente, anexe também o CPF'
  }, {
    text: 'Comprovante de endereço',
    value: 'residence_proof',
    description: 'Recente (de até 2 meses). Ex: alguma conta de consumo em geral (telefone, luz, água, etc.) ou correspondência bancária'
  }, {
    text: 'Certidão de estado civil',
    value: 'stable_union_certificate',
    description: 'Declaração para comprovação de estado civil. Solteiro: cópia da certidão de nascimento. Casado: cópia da certidão de casamento, escritura de pacto antenupcial. Viúvo: cópia da certidão de casamento, cópia da certidão de óbito. Separado/Divorciado: certidão de casamento com averbação'
  }, {
    text: 'Comprovante de domicílio bancário',
    value: 'banking_domicile_statement'
  }, {
    text: 'Holerites',
    value: 'paycheck',
    description: 'Últimos 3 meses'
  }, {
    text: 'Carteira profissional',
    value: 'ctps'
  }, {
    text: 'Declaração de imposto de renda completa',
    value: 'irpf',
    description: 'Último ano. Caso seja empresário, inclua a declaração dos últimos dois anos'
  }, {
    text: 'Declaração pessoal de saúde',
    value: 'personal_health_declaration'
  }, {
    text: 'Extrato de conta corrente (pessoa física)',
    value: 'personal_bank_statement',
    description: 'Últimos 6 meses'
  }, {
    text: 'Extrato de conta corrente (pessoa jurídica)',
    value: 'company_bank_statement'
  }, {
    text: 'Declaração de imposto de renda (pessoa jurídica)',
    value: 'irpj',
    description: 'Último ano'
  }, {
    text: 'Contrato social e última alteração',
    value: 'social_contract'
  }, {
    text: 'Relação de faturamento',
    value: 'company_income_proof'
  }, {
    text: 'Balanço e DRE',
    value: 'balance_sheet_and_income_statement'
  }, {
    text: 'Declaração de próprio punho sobre a situação profissional',
    value: 'professional_statement'
  }, {
    text: 'Contrato de prestação de serviços',
    value: 'work_for_hire_certified_copy'
  }, {
    text: 'Notas fiscais de serviços',
    value: 'service_invoice_certified_copy'
  }, {
    text: 'Holerite de pagamento do benefício',
    value: 'benefit_paycheck'
  }, {
    text: 'Carta de concessão do benefício',
    value: 'retirement_proof'
  }, {
    text: 'Matrícula dos imóveis alugados',
    value: 'leased_real_estate_registration'
  }, {
    text: 'Contrato de locação',
    value: 'lease_agreement'
  }];

  var creditAnalysisDocuments = exports.creditAnalysisDocuments = [{
    text: 'Aprovação de crédito',
    value: 'credit_approval'
  }, {
    text: 'Certidão de Nascimento',
    value: 'marriage_certificate'
  }, {
    text: 'BACEN',
    value: 'bacen'
  }, {
    text: 'SERASA',
    value: 'serasa'
  }, {
    text: 'Planilha de Crédito',
    value: 'credit_spreadsheet'
  }];

  var applicationLegalDocuments = exports.applicationLegalDocuments = [{
    text: 'Contrato AF - Fase de assinatura',
    value: 'home_refinancing_contract'
  }, {
    text: 'Contrato AF - Fase de registro',
    value: 'registered_home_refinancing_contract'
  }, {
    text: 'CCI - Fase de assinatura',
    value: 'mortgage_loan_document'
  }, {
    text: 'CCI - Fase de registro',
    value: 'annotated_mortgage_loan_document'
  }, {
    text: 'Aditivo Contrato AF - Fase de assinatura',
    value: 'home_refinancing_contract_addition'
  }, {
    text: 'Aditivo Contrato AF - Fase de registro',
    value: 'registered_home_refinancing_contract_addition'
  }, {
    text: 'Aditivo CCI - Fase de assinatura',
    value: 'mortgage_loan_document_addition'
  }, {
    text: 'Aditivo CCI - Fase de registro',
    value: 'annotated_mortgage_loan_document_addition'
  }, {
    text: 'Matrícula atualizada',
    value: 'up_to_date_deed'
  }, {
    text: 'Matrícula registrada',
    value: 'registered_deed'
  }, {
    text: 'Autorização para quitação de dívidas',
    value: 'debts_payment_authorization'
  }, {
    text: 'Recibo de Custódia de Valores - Cheques',
    value: 'custody_of_values_receipt'
  }, {
    text: 'Prenotação',
    value: 'provisory_notation'
  }, {
    text: 'Exigência',
    value: 'notary_request'
  }, {
    text: 'Custas do Registro',
    value: 'notary_fee'
  }, {
    text: 'Boleto de Quitação de Dívida',
    value: 'settlement_bill'
  }, {
    text: 'Comprovante de Pagamento da Dívida',
    value: 'debts_payment_receipt'
  }, {
    text: 'Comprovante de Liberação de Recurso',
    value: 'funds_liberation_proof'
  }, {
    text: 'Termo de Quitação de AF (IQ)',
    value: 'settlement_term'
  }, {
    text: 'Procuração para Termo de Quitação de AF (IQ)',
    value: 'power_of_attorney_to_settlement_term'
  }, {
    text: 'Termo de Cessão - Compra',
    value: 'term_of_assignment_on_acquisition'
  }, {
    text: 'Termo de Cessão - Venda',
    value: 'term_of_assignment_on_sale'
  }];

  var personLegalDocuments = exports.personLegalDocuments = [{
    text: "CNDT - Certidão Negativa de Débitos Trabalhistas (TST)",
    value: 'cndt'
  }, {
    text: "CCN - Certidão de Débitos Relativos a Créditos Tributários Federais e à Dívida Ativa da União - Relatório Fiscal e Complementar (RFB)",
    value: 'ccn'
  }, {
    text: "Certidões Cíveis, Família e Executivos Fiscais (Estaduais)",
    value: 'civil_certificates'
  }, {
    text: "Certidões de Tutela, Curatela e Interdições",
    value: 'guardianship_certificates'
  }, {
    text: "Certidões de Ações Trabalhistas",
    value: 'labor_lawsuits_certificates'
  }, {
    text: "Certidões da Justiça Federal 1ª e 2ª instâncias",
    value: 'federal_court_certificates'
  }, {
    text: "Certidões de Protestos - Comprovante de pagamento",
    value: 'protest_certificates'
  }, {
    text: "Certidões da Justiça do Trabalho (TRT)",
    value: 'trt'
  }, {
    text: "Certidão da Justiça Municipal e Estadual 1° Instância",
    value: 'first_instance_state_and_municipal_courts_certificates'
  }, {
    text: "Certidão da Justiça Municipal e Estadual 2° Instância",
    value: 'second_instance_state_and_municipal_courts_certificates'
  }, {
    text: "Outras Certidões",
    value: 'other_certificates'
  }];

  var allDocuments = [].concat(formDocuments, realEstateDocuments, personalDocuments, creditAnalysisDocuments, applicationLegalDocuments, personLegalDocuments);

  exports.default = allDocuments;
  var values = exports.values = _lodash.default.map(allDocuments, 'value');
});