define('ember-cli-bkf-core/mixins/auto-refi/partner-application-relationships-mixin', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    bancoPanApplication: _emberData.default.belongsTo('underwriting/auto-refi/banco-pan/application'),
    bvApplication: _emberData.default.belongsTo('underwriting/auto-refi/bv/application'),
    fidcApplication: _emberData.default.belongsTo('underwriting/auto-refi/fidc/application'),
    santanaApplication: _emberData.default.belongsTo('underwriting/auto-refi/santana/application'),

    partnerApplications: Ember.computed(function () {
      return [this.get('bancoPanApplication'), this.get('bvApplication'), this.get('fidcApplication'), this.get('santanaApplication')];
    }),

    presentPartnerApplications: Ember.computed('partnerApplications.@each.content', function () {
      return this.get('partnerApplications').filter(function (pa) {
        return pa.get('content') != null;
      });
    })
  });
});