define('ember-cli-bkf-core/authenticators/oauth2-base', ['exports', 'ember-cli-bkf-core/configuration', 'ember-simple-auth/authenticators/oauth2-password-grant'], function (exports, _configuration, _oauth2PasswordGrant) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _oauth2PasswordGrant.default.extend({
    tokenPath: null,

    serverTokenEndpoint: Ember.computed(function () {
      var apiHost = Ember.get(_configuration.default, 'apiHost');
      var tokenPath = this.get('tokenPath');

      return apiHost + '/' + tokenPath;
    })
  });
});