define('ember-cli-bkf-core/enums/income-type', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.incomeType = undefined;
  var incomeType = exports.incomeType = [{
    value: 'rental',
    text: 'Locação de bens'
  }, {
    value: 'service_delivery',
    text: 'Prestação de serviços'
  }, {
    value: 'pension',
    text: 'Pensão'
  }, {
    value: 'own_company',
    text: 'Empresa(s)'
  }, {
    value: 'retirement',
    text: 'Previdência/Aposentaria'
  }, {
    value: 'spouse',
    text: 'Renda do cônjuge'
  }, {
    value: 'others',
    text: 'Outros'
  }];

  exports.default = incomeType;
  var values = exports.values = _lodash.default.map(incomeType, 'value');
});