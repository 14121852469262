enifed('ember-routing/ext/run_loop', ['ember-metal'], function (_emberMetal) {
  'use strict';

  /**
  @module ember
  @submodule ember-views
  */

  // Add a new named queue after the 'actions' queue (where RSVP promises
  // resolve), which is used in router transitions to prevent unnecessary
  // loading state entry if all context promises resolve on the
  // 'actions' queue first.

  _emberMetal.run._addQueue('routerTransitions', 'actions');
});