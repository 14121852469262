define('ember-cli-bkf-core/services/pusher', ['exports', 'pusher', 'ember-cli-bkf-core/configuration'], function (exports, _pusher, _configuration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Service.extend({
    _pusherClient: Ember.computed(function () {
      var key = Ember.get(_configuration.default, 'pusherKey');

      return new _pusher.default(key, {
        encrypted: true
      });
    }),

    subscribe: function subscribe(channel, eventName, target) {
      var _Ember$run;

      var subscription = this.get('_pusherClient').subscribe(channel);

      for (var _len = arguments.length, bindArgs = Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
        bindArgs[_key - 3] = arguments[_key];
      }

      var handler = (_Ember$run = Ember.run).bind.apply(_Ember$run, [target].concat(_toConsumableArray(bindArgs)));
      subscription.bind(eventName, handler);

      return {
        teardown: function teardown() {
          subscription.unbind(eventName, handler);
        }
      };
    },
    promiseForEvent: function promiseForEvent(channel, eventName) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        var subscription = void 0;

        var handler = function handler() {
          subscription.teardown();
          resolve.apply(undefined, arguments);
        };

        subscription = _this.subscribe(channel, eventName, _this, handler);
      });
    }
  });
});