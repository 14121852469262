define('ember-cli-bkf-core/models/underwriting/auto-refi/partner-application-mixin', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    defaultBranch: _emberData.default.belongsTo('underwriting/branch'),

    sentBranch: _emberData.default.belongsTo('underwriting/branch'),

    possibleBranches: _emberData.default.hasMany('underwriting/branch'),

    status: _emberData.default.attr('string'),

    isEligible: Ember.computed.equal('status', 'ready')
  });
});