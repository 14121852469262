define('ember-cli-bkf-core/enums/auto-refi-time-range', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = undefined;


  var timeRanges = [{
    value: 'less_than_six_months',
    text: 'Menos de 6 meses'
  }, {
    value: 'between_six_months_and_one_year',
    text: 'De 6 meses a 1 ano'
  }, {
    value: 'between_one_and_two_years',
    text: 'De 1 a 2 anos'
  }, {
    value: 'between_two_and_three_years',
    text: 'De 2 a 3 anos'
  }, {
    value: 'between_three_and_four_years',
    text: 'De 3 a 4 anos'
  }, {
    value: 'between_four_and_five_years',
    text: 'De 4 a 5 anos'
  }, {
    value: 'between_five_and_ten_years',
    text: 'De 5 a 10 anos'
  }, {
    value: 'more_than_ten_years',
    text: 'Mais de 10 anos'
  }];

  exports.default = timeRanges;
  var values = exports.values = _lodash.default.map(timeRanges, 'value');
});