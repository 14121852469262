define('ember-cli-bkf-core/models/loan/auto-refi/bkf/person', ['exports', 'ember-data', 'ember-cp-validations', 'ember-cli-bkf-core/enums'], function (exports, _emberData, _emberCpValidations, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    fullName: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 0,
      max: 200
    }),

    motherFullName: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 0,
      max: 200
    }),

    spouseFullName: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 0,
      max: 200
    }),

    maritalStatus: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.maritalStatusValues
    }),

    cityOfBirth: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 2,
      max: 40
    }),

    stateOfBirth: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      min: 2,
      max: 40
    }),

    gender: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.genderValues
    }),

    cpf: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      is: 11
    }),

    rgNumber: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 3,
      max: 15
    }),

    rgIssuer: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 3,
      max: 15
    }),

    cnh: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 6,
      max: 14
    }),

    personalReferenceName: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 2,
      max: 50
    }),

    personalReferencePhoneCode: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      is: 2
    }),

    personalReferencePhone: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 8,
      max: 9
    }),

    personalReferenceConnection: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 2,
      max: 50
    }),

    bestContactPeriod: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.bestContactPeriodValues
    }),

    monthlyIncome: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      gte: 0
    }),

    incomeProof: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.incomeProofValues
    }),

    contactEmail: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 6,
      max: 100
    }),

    contactResidentialTelephoneCode: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      is: 2
    }),

    contactResidentialTelephone: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 8,
      max: 9
    }),

    contactCellphoneCode: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      is: 2
    }),

    contactCellphone: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 8,
      max: 9
    }),

    contactProfessionalPhone: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 8,
      max: 9
    }),

    contactProfessionalPhoneCode: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      is: 2
    }),

    financeBankNumber: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 1,
      max: 3
    }),

    financeBankAgencyNumber: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 3,
      max: 10
    }),

    financeBankAccountNumber: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 3,
      max: 15
    }),

    financeBankAccountTime: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.autoRefiTimeRangeValues
    })
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    residenceAddress: _emberData.default.belongsTo('loan/auto-refi/bkf/address'),
    employments: _emberData.default.hasMany('loan/auto-refi/bkf/employment'),
    documents: _emberData.default.hasMany('loan/auto-refi/bkf/document'),
    bacenAuthorizationSantana: _emberData.default.belongsTo('bacen-authorizations/santana/credit-check'),

    fullName: _emberData.default.attr('string'),
    motherFullName: _emberData.default.attr('string'),
    spouseFullName: _emberData.default.attr('string'),
    birthDate: _emberData.default.attr('pure-date'),
    spouseBirthDate: _emberData.default.attr('pure-date'),
    maritalStatus: _emberData.default.attr('string'),
    stableUnion: _emberData.default.attr(),
    cityOfBirth: _emberData.default.attr('string'),
    stateOfBirth: _emberData.default.attr('string'),
    gender: _emberData.default.attr('string'),
    rgNumber: _emberData.default.attr('string'),
    rgIssuer: _emberData.default.attr('string'),
    rgExpeditionDate: _emberData.default.attr('pure-date'),
    cpf: _emberData.default.attr('string'),
    cnh: _emberData.default.attr('string'),
    personalReferenceName: _emberData.default.attr('string'),
    personalReferencePhoneCode: _emberData.default.attr('string'),
    personalReferencePhone: _emberData.default.attr('string'),
    personalReferenceConnection: _emberData.default.attr('string'),
    bestContactPeriod: _emberData.default.attr('string'),
    monthlyIncome: _emberData.default.attr('number'),
    incomeProof: _emberData.default.attr('string'),
    contactEmail: _emberData.default.attr('string'),
    contactResidentialTelephoneCode: _emberData.default.attr('string'),
    contactResidentialTelephone: _emberData.default.attr('string'),
    contactCellphoneCode: _emberData.default.attr('string'),
    contactCellphone: _emberData.default.attr('string'),
    contactProfessionalPhoneCode: _emberData.default.attr('string'),
    contactProfessionalPhone: _emberData.default.attr('string'),
    financeBankNumber: _emberData.default.attr('string'),
    financeBankAgencyNumber: _emberData.default.attr('string'),
    financeBankAccountNumber: _emberData.default.attr('string'),
    financeBankAccountTime: _emberData.default.attr('string'),

    residenceOwner: _emberData.default.attr(),

    cnhUploadUrl: _emberData.default.attr('string'),
    incomeProofUploadUrl: _emberData.default.attr('string'),
    residenceProofUploadUrl: _emberData.default.attr('string'),
    bacenFormUploadUrl: _emberData.default.attr('string')
  });
});