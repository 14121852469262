define('ember-i18n-cp-validations/validators/messages', ['exports', 'ember', 'ember-cp-validations/validators/messages'], function (exports, _ember, _emberCpValidationsValidatorsMessages) {
  var Handlebars = _ember['default'].Handlebars;
  var warn = _ember['default'].warn;
  var computed = _ember['default'].computed;
  var isPresent = _ember['default'].isPresent;
  var isEmpty = _ember['default'].isEmpty;
  var isNone = _ember['default'].isNone;
  var inject = _ember['default'].inject;
  var get = _ember['default'].get;
  var getOwner = _ember['default'].getOwner;
  var isHTMLSafe = _ember['default'].String.isHTMLSafe;

  function isSafeString(input) {
    return typeof isHTMLSafe === 'function' ? isHTMLSafe(input) : input instanceof Handlebars.SafeString;
  }

  function unwrap(input) {
    if (isSafeString(input)) {
      return input.toString();
    }

    return input;
  }

  function emitWarning(msg, meta, ENV) {
    if (!get(ENV, 'i18n.suppressWarnings')) {
      warn(msg, false, meta);
    }
  }

  exports['default'] = _emberCpValidationsValidatorsMessages['default'].extend({
    i18n: inject.service(),
    _regex: /\{\{(\w+)\}\}|\{(\w+)\}/g,

    _prefix: computed('prefix', function () {
      var prefix = get(this, 'prefix');

      if (typeof prefix === 'string') {
        if (prefix.length) {
          return prefix + '.';
        }

        return prefix;
      }

      return 'errors.';
    }),

    init: function init() {
      this._super.apply(this, arguments);

      this._config = getOwner(this).resolveRegistration('config:environment') || {};
    },

    getDescriptionFor: function getDescriptionFor(attribute) {
      var context = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      var prefix = get(this, '_prefix');
      var key = prefix + 'description';
      var setDescriptionKey = undefined;

      if (!isEmpty(context.descriptionKey)) {
        key = context.descriptionKey;
        setDescriptionKey = true;
      } else if (!isEmpty(context.description)) {
        return context.description;
      }

      var i18n = get(this, 'i18n');

      if (i18n && i18n.exists(key)) {
        return unwrap(i18n.t(key, context));
      }

      if (setDescriptionKey) {
        emitWarning('Custom descriptionKey ' + key + ' provided but does not exist in i18n translations.', {
          id: 'ember-i18n-cp-validations-missing-description-key'
        }, this._config);
      }

      return this._super.apply(this, arguments);
    },

    getMessageFor: function getMessageFor(type) {
      var context = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      var i18n = get(this, 'i18n');
      var prefix = get(this, '_prefix');
      var key = isPresent(context.messageKey) ? context.messageKey : '' + prefix + type;

      if (i18n && i18n.exists(key)) {
        return unwrap(i18n.t(key, context));
      }

      emitWarning('[ember-i18n-cp-validations] Missing translation for validation key: ' + key + '\nhttp://offirgolan.github.io/ember-cp-validations/docs/messages/index.html', {
        id: 'ember-i18n-cp-validations-missing-translation'
      }, this._config);

      return this._super.apply(this, arguments);
    },

    formatMessage: function formatMessage(message, context) {
      var m = message;

      if (isNone(m) || typeof m !== 'string') {
        m = get(this, 'invalid');
      }

      return m.replace(get(this, '_regex'), function (s, p1, p2) {
        return get(context, p1 || p2);
      });
    }
  });
});