enifed('ember-glimmer/utils/string', ['exports', 'ember-debug'], function (exports, _emberDebug) {
  'use strict';

  exports.SafeString = undefined;
  exports.getSafeString = function () {
    false && !false && (0, _emberDebug.deprecate)('Ember.Handlebars.SafeString is deprecated in favor of Ember.String.htmlSafe', false, {
      id: 'ember-htmlbars.ember-handlebars-safestring',
      until: '3.0.0',
      url: 'https://emberjs.com/deprecations/v2.x#toc_use-ember-string-htmlsafe-over-ember-handlebars-safestring'
    });

    return SafeString;
  };
  exports.escapeExpression = function (string) {
    if (typeof string !== 'string') {
      // don't escape SafeStrings, since they're already safe
      if (string && string.toHTML) {
        return string.toHTML();
      } else if (string == null) {
        return '';
      } else if (!string) {
        return string + '';
      }

      // Force a string conversion as this will be done by the append regardless and
      // the regex test will do this transparently behind the scenes, causing issues if
      // an object's to string has escaped characters in it.
      string = '' + string;
    }

    if (!possible.test(string)) {
      return string;
    }
    return string.replace(badChars, escapeChar);
  }

  /**
    Mark a string as safe for unescaped output with Ember templates. If you
    return HTML from a helper, use this function to
    ensure Ember's rendering layer does not escape the HTML.
  
    ```javascript
    Ember.String.htmlSafe('<div>someString</div>')
    ```
  
    @method htmlSafe
    @for Ember.String
    @static
    @return {Handlebars.SafeString} A string that will not be HTML escaped by Handlebars.
    @public
  */
  ;
  exports.htmlSafe = function (str) {
    if (str === null || str === undefined) {
      str = '';
    } else if (typeof str !== 'string') {
      str = '' + str;
    }
    return new SafeString(str);
  }

  /**
    Detects if a string was decorated using `Ember.String.htmlSafe`.
  
    ```javascript
    var plainString = 'plain string',
        safeString = Ember.String.htmlSafe('<div>someValue</div>');
  
    Ember.String.isHTMLSafe(plainString); // false
    Ember.String.isHTMLSafe(safeString);  // true
    ```
  
    @method isHTMLSafe
    @for Ember.String
    @static
    @return {Boolean} `true` if the string was decorated with `htmlSafe`, `false` otherwise.
    @public
  */
  ;
  exports.isHTMLSafe = function (str) {
    return str && typeof str.toHTML === 'function';
  };

  var SafeString = exports.SafeString = function () {
    function SafeString(string) {

      this.string = string;
    }

    SafeString.prototype.toString = function () {
      return '' + this.string;
    };

    SafeString.prototype.toHTML = function () {
      return this.toString();
    };

    return SafeString;
  }();

  var escape = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    // jscs:disable
    "'": '&#x27;',
    // jscs:enable
    '`': '&#x60;',
    '=': '&#x3D;'
  };

  var possible = /[&<>"'`=]/;
  var badChars = /[&<>"'`=]/g;

  function escapeChar(chr) {
    return escape[chr];
  }
});