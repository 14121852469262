enifed('ember-runtime/mixins/controller_content_model_alias_deprecation', ['exports', 'ember-metal', 'ember-debug'], function (exports, _emberMetal, _emberDebug) {
  'use strict';

  exports.default = _emberMetal.Mixin.create({
    /**
      @private
       Moves `content` to `model`  at extend time if a `model` is not also specified.
       Note that this currently modifies the mixin themselves, which is technically
      dubious but is practically of little consequence. This may change in the
      future.
       @method willMergeMixin
      @since 1.4.0
    */
    willMergeMixin: function (props) {
      // Calling super is only OK here since we KNOW that
      // there is another Mixin loaded first.
      this._super.apply(this, arguments);

      var modelSpecified = !!props.model;

      if (props.content && !modelSpecified) {
        props.model = props.content;
        delete props['content'];

        false && !false && (0, _emberDebug.deprecate)('Do not specify `content` on a Controller, use `model` instead.', false, { id: 'ember-runtime.will-merge-mixin', until: '3.0.0' });
      }
    }
  });
});