define('ember-cli-hermes/services/hermes-worker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    setupPusher: function setupPusher(config) {
      this.sendMessage({
        type: 'config',
        message: config
      });
    },
    getRegistration: function getRegistration() {
      return navigator.serviceWorker.getRegistration();
    },
    connectVoiceChannel: function connectVoiceChannel(voiceId) {
      this.sendMessage({
        type: 'connect-voice',
        message: {
          channel: 'voice-' + voiceId
        }
      });
    },
    sendMessage: function sendMessage(message) {
      if (navigator.serviceWorker.controller) {
        try {
          navigator.serviceWorker.controller.postMessage(message);
          return true;
        } catch (e) {
          this.register();
        }
      }

      return false;
    },
    register: function register() {
      var _this = this;

      if ('serviceWorker' in navigator) {
        var serviceWorkerFileName = 'hermes-sw';

        navigator.serviceWorker.register('/' + serviceWorkerFileName + '.js').then(function (registration) {
          return _this.set('workerRegistration', registration);
        }).then(navigator.serviceWorker.ready).then(function () {
          _this.setupPusher(Ember.get(_this, 'config.pusher'));
        }).then(function () {
          return _this._registerEventSound();
        }).catch(function (error) {
          return console.log('Service worker registration failed:', error);
        });
      }
    },
    _registerEventSound: function _registerEventSound() {
      var _this2 = this;

      navigator.serviceWorker.addEventListener('message', function (event) {
        if (event.data.type === 'sound') {
          _this2.playSound(event.data.sound);
        }
      });
    },
    playSound: function playSound(sound) {
      var audio = new Audio(sound || '');
      audio.play();
    }
  });
});