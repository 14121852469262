define('ember-cli-bkf-core/models/loan/home-refi/bkf/address', ['exports', 'ember-data', 'ember-cp-validations', 'ember-cli-bkf-core/enums/residence-ownership', 'ember-cli-bkf-core/enums/state'], function (exports, _emberData, _emberCpValidations, _residenceOwnership, _state) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    zipcode: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      is: 8
    }),

    city: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 2, max: 40
    }),

    state: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _state.values
    }),

    neighborhood: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 3,
      max: 60
    }),

    streetAddress: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 3,
      max: 80
    }),

    streetNumber: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 1,
      max: 5
    }),

    streetComplement: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      max: 80
    }),

    country: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 2,
      max: 50
    }),

    residenceOwnership: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _residenceOwnership.values
    })
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    zipcode: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    neighborhood: _emberData.default.attr('string'),
    streetAddress: _emberData.default.attr('string'),
    streetNumber: _emberData.default.attr('string'),
    streetComplement: _emberData.default.attr('string'),
    residenceStartDate: _emberData.default.attr('pure-date'),
    residenceOwnership: _emberData.default.attr('string')
  });
});