define('ember-cli-bkf-core/enums/marital-status', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.maritalStatus = undefined;
  var maritalStatus = exports.maritalStatus = [{
    text: 'Solteiro',
    value: 'single'
  }, {
    text: 'Casado',
    value: 'married'
  }, {
    text: 'Divorciado',
    value: 'divorced'
  }, {
    text: 'Separado',
    value: 'separated'
  }, {
    text: 'Viúvo',
    value: 'widower'
  }];

  exports.default = maritalStatus;
  var values = exports.values = _lodash.default.map(maritalStatus, 'value');
});