define('ember-cli-bkf-core/models/loan/auto-refi/bkf/chosen-simulation', ['exports', 'ember-data/model', 'ember-data/relationships'], function (exports, _model, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    application: (0, _relationships.belongsTo)('loan/auto-refi/bkf/application'),
    simulation: (0, _relationships.belongsTo)('loan/auto-refi/bkf/simulation')
  });
});