define('ember-cli-bkf-core/enums/has-deed', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.hasDeed = undefined;
  var hasDeed = exports.hasDeed = [{
    text: 'Sim',
    value: 'yes'
  }, {
    text: 'Não',
    value: 'no'
  }, {
    text: 'Não sei',
    value: 'do_not_know'
  }];

  exports.default = hasDeed;
  var values = exports.values = _lodash.default.map(hasDeed, 'value');
});