define('ember-cli-bkf-core/enums/real-estate-type', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.realEstateType = undefined;
  var realEstateType = exports.realEstateType = [{
    text: 'Casa',
    value: 'house'
  }, {
    text: 'Apartamento',
    value: 'apartment'
  }, {
    text: 'Comercial',
    value: 'commercial'
  }, {
    text: 'Terreno',
    value: 'land'
  }];

  exports.default = realEstateType;
  var values = exports.values = _lodash.default.map(realEstateType, 'value');
});