define('ember-cli-bkf-core/models/loan/home-refi/bkf/employment', ['exports', 'ember-data', 'ember-cp-validations', 'ember-cli-bkf-core/enums/professional-status', 'ember-cli-bkf-core/enums/activity-field'], function (exports, _emberData, _emberCpValidations, _professionalStatus, _activityField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    companyName: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 2,
      max: 60
    }),

    companyCnpj: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      is: 14
    }),

    jobTitle: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 3,
      max: 30
    }),

    profession: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 2,
      max: 200
    }),

    professionalStatus: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _professionalStatus.values
    }),

    companyActivityField: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _activityField.values
    }),

    type: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 3,
      max: 15
    }),

    phoneCode: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      is: 2
    }),

    phone: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 8,
      max: 9
    })
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    companyAddress: _emberData.default.belongsTo('loan/home-refi/bkf/address'),

    jobTitle: _emberData.default.attr('string'),
    companyName: _emberData.default.attr('string'),
    companyCnpj: _emberData.default.attr('string'),
    companyActivityField: _emberData.default.attr('string'),
    profession: _emberData.default.attr('string'),
    professionalStatus: _emberData.default.attr('string'),
    startDate: _emberData.default.attr('pure-date'),
    endDate: _emberData.default.attr('pure-date'),
    phoneCode: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string')
  });
});