define('ember-cli-hermes/message-request-permission-blocked', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    var el = document.createElement('DIV');
    el.style.cssText = STYLES.join(';');
    el.className = 'ui message red';
    el.innerHTML = 'O MDO precisa de permissão para enviar notificações para você. Porém algo deu errado, entre em contato com o squad de comunicação no Slack em <a target="blank" href="https://creditas.slack.com/messages/C8M58EVNE/">#comunicação</a>.';

    return el;
  };

  var STYLES = ['border:none', 'border-radius: 0', 'box-shadow: none', 'text-align: center', 'font-weight: 700', 'font-size: 14px'];
});