define('ember-cli-bkf-core/serializers/bkf-core', ['exports', 'ember-data', 'lodash', 'ember-cli-bkf-core/adapters/bkf-core'], function (exports, _emberData, _lodash, _bkfCore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _emberData.default.JSONAPISerializer.extend({
    i18n: Ember.inject.service(),

    recordSelfLink: Ember.inject.service(),

    normalize: function normalize(model, hash) {
      var links = hash.links || {};

      if ('self' in links) {
        var modelName = this.modelNameFromPayloadKey(hash.type);

        this.get('recordSelfLink').setSelfLink({ modelName: modelName, id: hash.id }, links.self);
      }

      for (var _len = arguments.length, args = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      return this._super.apply(this, [model, hash].concat(_toConsumableArray(args)));
    },


    /**
     * Override the default `serializeAttribute` method to implement partial record updates. When the
     * snapshot gets marked with the FOR_UPDATE Symbol by the adapter we assume that only changed
     * properties should be serialized.
     **/
    serializeAttribute: function serializeAttribute(snapshot, data, attributeName) {
      if (!snapshot[_bkfCore.FOR_UPDATE] || attributeName in snapshot.changedAttributes()) {
        return this._super.apply(this, arguments);
      }
    },
    extractAttributes: function extractAttributes() {
      var attributes = this._super.apply(this, arguments);

      Object.keys(attributes).forEach(function (key) {
        var value = attributes[key];
        if (_lodash.default.isString(value)) {
          value = value.trim();

          if (_lodash.default.isEmpty(value)) {
            attributes[key] = null;
          }
        }
      });

      return attributes;
    },
    extractErrors: function extractErrors(store, typeClass, payload, id) {
      var mergedPayload = payload;
      var i18n = this.get('i18n');

      if (_lodash.default.has(payload, 'errors')) {
        var newErrors = payload.errors.map(function (err) {
          var i18nPath = 'backendErrors.' + err.code;

          var hasTranslation = i18n.exists(i18nPath);
          if (!hasTranslation) {
            return err;
          }

          var translation = i18n.t(i18nPath, err.meta);
          return _lodash.default.merge({ detail: translation }, err);
        });

        mergedPayload = _lodash.default.merge({ errors: newErrors }, payload);
      }

      return this._super(store, typeClass, mergedPayload, id);
    },
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      var withSlashes = payloadKey.replace(/ /g, '/');
      return this._super(withSlashes);
    },
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      var withSpaces = modelName.replace(/\//g, ' ');
      return this._super(withSpaces);
    },


    // core project api does not support hasMany relationships
    shouldSerializeHasMany: function shouldSerializeHasMany() /* snapshot, key, relationship */{
      return false;
    }
  });
});