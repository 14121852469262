enifed('ember-runtime/mixins/-proxy', ['exports', 'ember-babel', '@glimmer/reference', 'ember-metal', 'ember-debug', 'ember-runtime/computed/computed_macros'], function (exports, _emberBabel, _reference, _emberMetal, _emberDebug, _computed_macros) {
  'use strict';

  /**
  @module ember
  @submodule ember-runtime
  */

  function contentPropertyWillChange(content, contentKey) {
    var key = contentKey.slice(8); // remove "content."
    if (key in this) {
      return;
    } // if shadowed in proxy
    (0, _emberMetal.propertyWillChange)(this, key);
  }

  function contentPropertyDidChange(content, contentKey) {
    var key = contentKey.slice(8); // remove "content."
    if (key in this) {
      return;
    } // if shadowed in proxy
    (0, _emberMetal.propertyDidChange)(this, key);
  }

  var ProxyTag = function (_CachedTag) {
    (0, _emberBabel.inherits)(ProxyTag, _CachedTag);

    function ProxyTag(proxy) {

      var _this = (0, _emberBabel.possibleConstructorReturn)(this, _CachedTag.call(this));

      var content = (0, _emberMetal.get)(proxy, 'content');

      _this.proxy = proxy;
      _this.proxyWrapperTag = new _reference.DirtyableTag();
      _this.proxyContentTag = new _reference.UpdatableTag((0, _emberMetal.tagFor)(content));
      return _this;
    }

    ProxyTag.prototype.compute = function () {
      return Math.max(this.proxyWrapperTag.value(), this.proxyContentTag.value());
    };

    ProxyTag.prototype.dirty = function () {
      this.proxyWrapperTag.dirty();
    };

    ProxyTag.prototype.contentDidChange = function () {
      var content = (0, _emberMetal.get)(this.proxy, 'content');
      this.proxyContentTag.update((0, _emberMetal.tagFor)(content));
    };

    return ProxyTag;
  }(_reference.CachedTag);

  exports.default = _emberMetal.Mixin.create({
    /**
      The object whose properties will be forwarded.
       @property content
      @type Ember.Object
      @default null
      @private
    */
    content: null,

    init: function () {
      this._super.apply(this, arguments);
      var m = (0, _emberMetal.meta)(this);
      m.setProxy();
      m.setTag(new ProxyTag(this));
    },

    isTruthy: (0, _computed_macros.bool)('content'),

    willWatchProperty: function (key) {
      var contentKey = 'content.' + key;
      (0, _emberMetal._addBeforeObserver)(this, contentKey, null, contentPropertyWillChange);
      (0, _emberMetal.addObserver)(this, contentKey, null, contentPropertyDidChange);
    },
    didUnwatchProperty: function (key) {
      var contentKey = 'content.' + key;
      (0, _emberMetal._removeBeforeObserver)(this, contentKey, null, contentPropertyWillChange);
      (0, _emberMetal.removeObserver)(this, contentKey, null, contentPropertyDidChange);
    },
    unknownProperty: function (key) {
      var content = (0, _emberMetal.get)(this, 'content');
      if (content) {
        false && !!this.isController && (0, _emberDebug.deprecate)('You attempted to access `' + key + '` from `' + this + '`, but object proxying is deprecated. Please use `model.' + key + '` instead.', !this.isController, { id: 'ember-runtime.controller-proxy', until: '3.0.0' });

        return (0, _emberMetal.get)(content, key);
      }
    },
    setUnknownProperty: function (key, value) {
      var m = (0, _emberMetal.meta)(this);

      if (m.proto === this) {
        // if marked as prototype then just defineProperty
        // rather than delegate
        (0, _emberMetal.defineProperty)(this, key, null, value);
        return value;
      }

      var content = (0, _emberMetal.get)(this, 'content');
      false && !content && (0, _emberDebug.assert)('Cannot delegate set(\'' + key + '\', ' + value + ') to the \'content\' property of object proxy ' + this + ': its \'content\' is undefined.', content);
      false && !!this.isController && (0, _emberDebug.deprecate)('You attempted to set `' + key + '` from `' + this + '`, but object proxying is deprecated. Please use `model.' + key + '` instead.', !this.isController, { id: 'ember-runtime.controller-proxy', until: '3.0.0' });

      return (0, _emberMetal.set)(content, key, value);
    }
  });
});