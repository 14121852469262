define('ember-cli-bkf-core/enum-options', ['exports', 'ember-cli-bkf-core/enums', 'lodash'], function (exports, _enums, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.blankOption = undefined;
  exports.computed = computed;
  exports.default = enumOptions;
  exports.enumFromRegistry = enumFromRegistry;
  exports.enumFromAddon = enumFromAddon;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var blankOption = exports.blankOption = {
    text: 'Selecione um item',
    value: null
  };

  function computed(name, options) {
    return Ember.computed(function () {
      return enumOptions(name, Ember.getOwner(this), options);
    });
  }

  function enumOptions(name, owner) {
    var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
        includeBlank = _ref.includeBlank;

    var options = enumFromRegistry(name, owner) || enumFromAddon(name);

    if (options == null) {
      throw new Error('Invalid enum name: ' + name);
    }

    var result = _lodash.default.cloneDeep(options);

    if (includeBlank) {
      return [_lodash.default.clone(blankOption)].concat(_toConsumableArray(result));
    }

    return result;
  }

  function enumFromRegistry(name, owner) {
    var dasherized = Ember.String.dasherize(name);

    return owner.lookup('enum:' + dasherized);
  }

  function enumFromAddon(name) {
    var camelized = Ember.String.camelize(name);

    return _enums[camelized];
  }
});