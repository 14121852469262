define('ember-cli-bkf-core/enums/purpose', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.purpose = undefined;
  var purpose = exports.purpose = [{
    text: 'Pagamento de dívidas',
    value: 'debts_payment'
  }, {
    text: 'Investimento',
    value: 'investment_in_own_business'
  }, {
    text: 'Reforma de casa',
    value: 'real_estate_renovation'
  }, {
    text: 'Aquisição de Bens',
    value: 'goods_acquisition'
  }, {
    text: 'Refinanciamento de dívidas',
    value: 'debts_refinancing'
  }, {
    text: 'Outros',
    value: 'others'
  }];

  exports.default = purpose;
  var values = exports.values = _lodash.default.map(purpose, 'value');
});