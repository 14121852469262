define('ember-cli-bkf-core/models/loan/home-refi/bkf/checklist/document', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    person: (0, _relationships.belongsTo)('loan/home-refi/bkf/person'),
    application: (0, _relationships.belongsTo)('loan/home-refi/bkf/application'),

    category: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),
    filename: (0, _attr.default)('string'),
    uploadedBy: (0, _attr.default)('string')
  });
});