(function() {
  function vendorModule() {
    'use strict';

    return {
      'CPF': self['CPF'],
      'CNPJ': self['CNPJ'],

      __esModule: true,
    };
  }

  define('cpf-cnpj', [], vendorModule);
})();
