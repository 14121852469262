define('ember-cli-bkf-core/configuration', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    apiHost: '',
    pusherKey: null,

    load: function load(config) {
      var _this = this;

      Object.keys(config).forEach(function (property) {
        if (_this.hasOwnProperty(property)) {
          _this[property] = config[property];
        }
      });
    }
  };
});