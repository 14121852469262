define('ember-cli-bkf-core/models/crm/home-refi/lead', ['exports', 'ember-data', 'ember-cp-validations', 'ember-cli-bkf-core/enums/state', 'ember-cli-bkf-core/enums/purpose', 'ember-cli-bkf-core/enums/has-deed'], function (exports, _emberData, _emberCpValidations, _state, _purpose, _hasDeed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    purpose: [(0, _emberCpValidations.validator)('inclusion', {
      allowBlank: false,
      in: _purpose.values
    })],

    realEstateValue: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      allowBlank: false
    }), (0, _emberCpValidations.validator)('number', {
      gt: 10000,
      allowString: true
    })],

    familyMonthlyIncome: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 30,
      allowBlank: false
    }), (0, _emberCpValidations.validator)('number', {
      gt: 0,
      allowString: true
    })],

    realEstateZipCode: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      allowBlank: false,
      is: 8
    })],

    realEstateNumber: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      allowBlank: false,
      max: 10
    })],

    hasDeed: [(0, _emberCpValidations.validator)('inclusion', {
      allowBlank: false,
      in: _hasDeed.values
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    completed: _emberData.default.attr('boolean', { defaultValue: false }),
    purpose: _emberData.default.attr('string'),
    financingTimeMonths: _emberData.default.attr('number'),
    intendedCredit: _emberData.default.attr('number'),
    familyMonthlyIncome: _emberData.default.attr('number'),
    realEstateZipCode: _emberData.default.attr('string'),
    realEstateNumber: _emberData.default.attr('string'),
    realEstateValue: _emberData.default.attr('number'),
    realEstateDebt: _emberData.default.attr('number'),
    hasDeed: _emberData.default.attr('string'),
    source: _emberData.default.attr('string'),
    customer: _emberData.default.belongsTo('crm/customer')
  });
});