enifed('ember-routing/system/generate_controller', ['exports', 'ember-metal', 'ember-debug'], function (exports) {
  'use strict';

  exports.generateControllerFactory = generateControllerFactory;
  exports.default =

  /**
    Generates and instantiates a controller extending from `controller:basic`
    if present, or `Ember.Controller` if not.
  
    @for Ember
    @method generateController
    @private
    @since 1.3.0
  */
  function (owner, controllerName) {
    generateControllerFactory(owner, controllerName);

    var instance = owner.lookup('controller:' + controllerName);

    return instance;
  };

  /**
  @module ember
  @submodule ember-routing
  */

  /**
    Generates a controller factory
  
    @for Ember
    @method generateControllerFactory
    @private
  */

  function generateControllerFactory(owner, controllerName) {
    var Factory = owner.factoryFor('controller:basic').class;

    Factory = Factory.extend({
      toString: function () {
        return '(generated ' + controllerName + ' controller)';
      }
    });

    owner.register('controller:' + controllerName, Factory);

    return Factory;
  }
});