define('ember-cli-bkf-core/models/loan/auto-refi/bkf/employment', ['exports', 'ember-data', 'ember-cp-validations', 'ember-cli-bkf-core/enums'], function (exports, _emberData, _emberCpValidations, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    companyName: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 2,
      max: 150
    }),

    companyCnpj: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      is: 14
    }),

    jobTitle: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 3,
      max: 100
    }),

    professionalStatus: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.autoRefiProfessionalStatusValues
    }),

    classEntityRegistrationNumber: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 0,
      max: 255
    }),

    retirementNumber: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 4,
      max: 20
    }),

    timeOfEmployment: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.autoRefiTimeRangeValues
    })
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    person: _emberData.default.belongsTo('loan/auto-refi/bkf/person'),
    companyAddress: _emberData.default.belongsTo('loan/auto-refi/bkf/address'),

    companyName: _emberData.default.attr('string'),
    companyCnpj: _emberData.default.attr('string'),
    jobTitle: _emberData.default.attr('string'),
    professionalStatus: _emberData.default.attr('string'),
    retirementNumber: _emberData.default.attr('string'),
    classEntityRegistrationNumber: _emberData.default.attr('string'),
    timeOfEmployment: _emberData.default.attr('string')
  });
});