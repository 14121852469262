define('ember-cli-bkf-core/enums/partner-application-loan-goal', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.partnerApplicationLoanGoal = undefined;
  var partnerApplicationLoanGoal = exports.partnerApplicationLoanGoal = [{
    value: 'working_capital',
    text: 'Capital de giro'
  }, {
    value: 'investment_on_own_business',
    text: 'Investimento (negócio próprio)'
  }, {
    value: 'bank_debt_regularization',
    text: 'Regularizar restritivos bancários'
  }, {
    value: 'debt_regularization',
    text: 'Regularizar endividamentos'
  }, {
    value: 'real_estate_reform',
    text: 'Reforma de imóvel'
  }, {
    value: 'real_estate_acquisition',
    text: 'Aquisição de imóvel'
  }, {
    value: 'asset_acquisition',
    text: 'Aquisição de bens variados'
  }];

  exports.default = partnerApplicationLoanGoal;
  var values = exports.values = _lodash.default.map(partnerApplicationLoanGoal, 'value');
});