define('ember-cli-bkf-core/models/loan/home-refi/bkf/checklist/document-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    person: _emberData.default.belongsTo('loan/home-refi/bkf/person'),
    application: _emberData.default.belongsTo('loan/home-refi/bkf/application'),
    checklistDocuments: _emberData.default.hasMany('loan/home-refi/bkf/checklist/document'),

    name: _emberData.default.attr('string'),
    category: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    uploadUrl: _emberData.default.attr('string'),

    isUploadable: Ember.computed('uploadUrl', function () {
      var url = this.get('uploadUrl');

      return url != null && url.length > 0;
    })
  });
});