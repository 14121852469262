define('ember-cli-bkf-core/models/loan/home-refi/bkf/application', ['exports', 'ember-data', 'ember-cp-validations', 'ember-cli-bkf-core/enums'], function (exports, _emberData, _emberCpValidations, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    amortizationSystem: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 3, max: 7
    }),

    purpose: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.purposeValues
    }),

    extraPurpose: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      max: 60
    }),

    realEstateType: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.realEstateTypeValues
    }),

    realEstateContactInspectionName: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 5, max: 48
    }),

    realEstateContactInspectionPhoneCode: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      is: 2
    }),

    realEstateContactInspectionPhone: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 8, max: 9
    }),

    additionalRequesterKinship: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.requesterKinshipValues
    }),

    otherAdditionalRequesterKinship: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.requesterKinshipValues
    })
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    stepStates: _emberData.default.attr(),
    documentKey: _emberData.default.attr('string'),

    // people related to the application
    requester: _emberData.default.belongsTo('loan/home-refi/bkf/person'),
    additionalRequester: _emberData.default.belongsTo('loan/home-refi/bkf/person'),
    otherAdditionalRequester: _emberData.default.belongsTo('loan/home-refi/bkf/person'),
    realEstateOwner: _emberData.default.belongsTo('loan/home-refi/bkf/person'),

    realEstateAddress: _emberData.default.belongsTo('loan/home-refi/bkf/address'),

    // partner applications
    bariguiApplication: _emberData.default.belongsTo('underwriting/home-refi/barigui/application'),
    bancoPanApplication: _emberData.default.belongsTo('underwriting/home-refi/banco-pan/application'),
    bancoPaulistaApplication: _emberData.default.belongsTo('underwriting/home-refi/banco-paulista/application'),
    chbApplication: _emberData.default.belongsTo('underwriting/home-refi/chb/application'),
    fapaApplication: _emberData.default.belongsTo('underwriting/home-refi/fapa/application'),
    domusApplication: _emberData.default.belongsTo('underwriting/home-refi/domus/application'),
    chpApplication: _emberData.default.belongsTo('underwriting/home-refi/chp/application'),

    formStatus: _emberData.default.belongsTo('loan/home-refi/bkf/form-status'),

    checklistDocumentItems: _emberData.default.hasMany('loan/home-refi/bkf/checklist/document-item'),

    transactionCode: _emberData.default.attr('string'),

    additionalRequesterKinship: _emberData.default.attr('string'),
    otherAdditionalRequesterKinship: _emberData.default.attr('string'),

    deedInOrder: _emberData.default.attr('boolean'),
    declaresIncomeTax: _emberData.default.attr('boolean'),
    residencePrice: _emberData.default.attr('number'),
    intendedCredit: _emberData.default.attr('number'),
    financingTimeMonths: _emberData.default.attr('number'),
    amortizationSystem: _emberData.default.attr('string'),
    preferenceExpirationDay: _emberData.default.attr('number'),
    purpose: _emberData.default.attr('string'),
    extraPurpose: _emberData.default.attr('string'),

    financeRegistrationExpenditures: _emberData.default.attr(),
    financeAppraisalReport: _emberData.default.attr(),
    financeLegalAnalysis: _emberData.default.attr(),
    financeBrokerExpenditures: _emberData.default.attr(),

    realEstateContactInspectionName: _emberData.default.attr('string'),
    realEstateContactInspectionPhoneCode: _emberData.default.attr('string'),
    realEstateContactInspectionPhone: _emberData.default.attr('string'),
    realEstateDebtsTotalValue: _emberData.default.attr('number'),
    realEstateDebtsCreditorBankNumber: _emberData.default.attr('string'),
    realEstateEstimatedValue: _emberData.default.attr('number'),
    realEstateType: _emberData.default.attr('string'),

    otherRealEstatesQuantity: _emberData.default.attr('number'),
    otherRealEstatesTotalValue: _emberData.default.attr('number'),
    carsQuantity: _emberData.default.attr('number'),
    carsTotalValue: _emberData.default.attr('number')
  });
});