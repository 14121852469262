define('ember-cli-bkf-core/models/engine/auto-refi/report', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    status: _emberData.default.attr('string'),
    analysis: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    lead: _emberData.default.belongsTo('crm/auto-refi/lead')
  });
});