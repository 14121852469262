define('ember-cli-bkf-core/utils/translate-from-enum', ['exports', 'lodash', 'ember-cli-bkf-core/enum-options'], function (exports, _lodash, _enumOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = translateFromEnum;
  function translateFromEnum(enumPath, propertyPath) {
    return Ember.computed(propertyPath, function () {
      var value = this.get(propertyPath);

      var enumList = (0, _enumOptions.default)(enumPath, Ember.getOwner(this));
      var enumItem = _lodash.default.find(enumList, { value: value });

      if (enumItem) {
        return enumItem.text;
      }

      return value;
    });
  }
});