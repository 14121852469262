define('ember-cli-bkf-core/services/record-self-link', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    _map: Ember.computed(function () {
      return new Map();
    }),

    getSelfLink: function getSelfLink(_ref) {
      var id = _ref.id,
          modelName = _ref.modelName;

      var key = [id, modelName].join();

      return this.get('_map').get(key) || null;
    },
    setSelfLink: function setSelfLink(_ref2, link) {
      var id = _ref2.id,
          modelName = _ref2.modelName;

      var key = [id, modelName].join();

      this.get('_map').set(key, link);
    }
  });
});