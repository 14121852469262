define('ember-cli-bkf-core/models/underwriting/home-refi/chb/application', ['exports', 'ember-data', 'ember-cp-validations', 'ember-cli-bkf-core/enums'], function (exports, _emberData, _emberCpValidations, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    amortizationSystem: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.amortizationSystemValues
    }),

    realEstateUsage: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.realEstateUsageValues
    }),

    purpose: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.purposeValues
    })
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    bkfApplication: _emberData.default.belongsTo('loan/home-refi/bkf/application'),
    requester: _emberData.default.belongsTo('loan/home-refi/bkf/person'),
    additionalRequester: _emberData.default.belongsTo('loan/home-refi/bkf/person'),

    loanGoals: _emberData.default.attr(),
    loanGoalOthers: _emberData.default.attr('string'),
    intendedCredit: _emberData.default.attr('number'),
    financingTimeMonths: _emberData.default.attr('number'),
    amortizationSystem: _emberData.default.attr('string'),
    financingContractTax: _emberData.default.attr('number'),
    realEstateUsage: _emberData.default.attr('string'),
    realEstateCurrentFinancingBankNumber: _emberData.default.attr('string'),
    purpose: _emberData.default.attr('string'),
    binaryDocumentUrl: _emberData.default.attr('string')
  });
});