define('ember-cli-bkf-core/models/loan/auto-refi/bkf/address', ['exports', 'ember-data', 'ember-cp-validations', 'ember-cli-bkf-core/enums'], function (exports, _emberData, _emberCpValidations, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    zipCode: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      is: 8
    }),

    city: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 2,
      max: 60
    }),

    state: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.stateValues
    }),

    neighborhood: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 3,
      max: 60
    }),

    streetAddress: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 3,
      max: 250
    }),

    streetNumber: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 1,
      max: 10
    }),

    streetComplement: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      max: 200
    }),

    timeOfResidence: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.autoRefiTimeRangeValues
    })
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    zipCode: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    neighborhood: _emberData.default.attr('string'),
    streetAddress: _emberData.default.attr('string'),
    streetNumber: _emberData.default.attr('string'),
    streetComplement: _emberData.default.attr('string'),
    timeOfResidence: _emberData.default.attr('string')
  });
});