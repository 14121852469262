define('ember-cli-bkf-core/enums/partner', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.partner = undefined;
  var partner = exports.partner = [{
    text: 'Barigui',
    value: 'barigui',
    extension: 'xlsx'
  }, {
    text: 'Fapa',
    value: 'fapa',
    extension: 'pdf'
  }, {
    text: 'CHP',
    value: 'chp',
    extension: 'xlsx'
  }];

  exports.default = partner;
  var values = exports.values = _lodash.default.map(partner, 'value');
});