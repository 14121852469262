define('ember-cli-bkf-core/models/crm/auto-refi/leads/proposal', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    administrationFee: _emberData.default.attr('number'),
    cet: _emberData.default.attr('number'),
    financingTimeMonths: _emberData.default.attr('number'),
    inspectionValue: _emberData.default.attr('number'),
    installment: _emberData.default.attr('number'),
    insuranceValue: _emberData.default.attr('number'),
    intendedCredit: _emberData.default.attr('number'),
    interestRate: _emberData.default.attr('number'),
    iof: _emberData.default.attr('number'),
    notaryValue: _emberData.default.attr('number'),
    modified: _emberData.default.attr('boolean', { defaultValue: false }),
    lead: _emberData.default.belongsTo('crm/auto-refi/lead')
  });
});