define('ember-cli-bkf-core/enums/best-contact-period', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = undefined;


  var contactPeriods = [{
    value: 'morning',
    text: 'Manhã'
  }, {
    value: 'afternoon',
    text: 'Tarde'
  }, {
    value: 'evening',
    text: 'Noite'
  }];

  exports.default = contactPeriods;
  var values = exports.values = _lodash.default.map(contactPeriods, 'value');
});