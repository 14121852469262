define('ember-cli-bkf-core/models/loan/auto-refi/bkf/application', ['exports', 'ember-data', 'ember-cp-validations', 'ember-cli-bkf-core/mixins/auto-refi/partner-application-relationships-mixin', 'ember-cli-bkf-core/enums'], function (exports, _emberData, _emberCpValidations, _partnerApplicationRelationshipsMixin, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    loanAmount: (0, _emberCpValidations.validator)('number', {
      positive: true,
      allowBlank: true
    }),

    purpose: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.purposeValues
    }),

    vehicleDebt: (0, _emberCpValidations.validator)('number', {
      positive: true,
      allowBlank: true
    }),

    vehicleManufacturingYear: (0, _emberCpValidations.validator)('date', {
      format: 'YYYY'
    }),

    vehicleModelYear: (0, _emberCpValidations.validator)('date', {
      format: 'YYYY'
    }),

    vehicleBrand: (0, _emberCpValidations.validator)('length', {
      max: 20,
      allowBlank: true
    }),

    vehicleModel: (0, _emberCpValidations.validator)('length', {
      max: 100,
      allowBlank: true
    }),

    licensePlate: (0, _emberCpValidations.validator)('length', {
      min: 7,
      max: 8,
      allowBlank: true
    }),

    renavam: (0, _emberCpValidations.validator)('length', {
      is: 11,
      allowBlank: true
    }),

    purchaseValue: (0, _emberCpValidations.validator)('number', {
      positive: true,
      allowBlank: true
    }),

    downPayment: (0, _emberCpValidations.validator)('number', {
      positive: true,
      allowBlank: true
    }),

    interestTable: (0, _emberCpValidations.validator)('length', {
      min: 1,
      max: 10,
      allowBlank: true
    }),

    financingTimeMonths: (0, _emberCpValidations.validator)('number', {
      positive: true,
      allowBlank: true
    }),

    installmentValue: (0, _emberCpValidations.validator)('number', {
      positive: true,
      allowBlank: true
    }),

    returnValue: (0, _emberCpValidations.validator)('length', {
      min: 1,
      max: 2,
      allowBlank: true
    }),

    insuranceValue: (0, _emberCpValidations.validator)('number', {
      positive: true,
      allowBlank: true
    }),

    partnerName: (0, _emberCpValidations.validator)('length', {
      min: 1,
      max: 30,
      allowBlank: true
    }),

    partnerBranch: (0, _emberCpValidations.validator)('length', {
      min: 1,
      max: 40,
      allowBlank: true
    }),

    branchResponsible: (0, _emberCpValidations.validator)('length', {
      min: 1,
      max: 40,
      allowBlank: true
    }),

    creditDefense: (0, _emberCpValidations.validator)('length', {
      min: 0,
      max: 4096,

      allowBlank: true
    }),

    scribble: (0, _emberCpValidations.validator)('length', {
      min: 0,
      max: 2048,

      allowBlank: true
    }),

    pendingDocuments: (0, _emberCpValidations.validator)('length', {
      min: 0,
      max: 2048,

      allowBlank: true
    })
  });

  exports.default = _emberData.default.Model.extend(Validations, _partnerApplicationRelationshipsMixin.default, {
    requester: _emberData.default.belongsTo('loan/auto-refi/bkf/person'),
    guarantor: _emberData.default.belongsTo('loan/auto-refi/bkf/person'),

    customerFormStatus: _emberData.default.belongsTo('loan/auto-refi/bkf/form-status'),
    consultantFormStatus: _emberData.default.belongsTo('loan/auto-refi/bkf/form-status'),
    serasaScoreRange: _emberData.default.attr('string'),

    documents: _emberData.default.hasMany('loan/auto-refi/bkf/document'),

    simulations: _emberData.default.hasMany('loan/auto-refi/bkf/simulation'),
    chosenSimulation: _emberData.default.belongsTo('loan/auto-refi/bkf/chosen-simulation'),

    source: _emberData.default.attr('string'),
    isFromPromoter: Ember.computed('source', function () {
      var source = this.get('source');
      return (/^PR[0-9]{3}$/.test(source)
      );
    }),

    loanAmount: _emberData.default.attr('number'),
    purpose: _emberData.default.attr('string'),
    vehicleModelYear: _emberData.default.attr('number'),
    vehicleBrand: _emberData.default.attr('string'),
    vehicleModel: _emberData.default.attr('string'),
    vehicleOwner: _emberData.default.attr(),
    vehicleDebt: _emberData.default.attr('number'),
    vehicleManufacturingYear: _emberData.default.attr('number'),
    vehicleCategory: _emberData.default.attr('string'),
    molicarCode: _emberData.default.attr('string'),

    licensePlate: _emberData.default.attr('string'),
    renavam: _emberData.default.attr('string'),
    purchaseValue: _emberData.default.attr('number'),
    downPayment: _emberData.default.attr('number'),
    interestTable: _emberData.default.attr('string'),
    financingTimeMonths: _emberData.default.attr('number'),
    installmentValue: _emberData.default.attr('number'),
    returnValue: _emberData.default.attr('string'),
    insuranceValue: _emberData.default.attr('number'),
    partnerName: _emberData.default.attr('string'),
    partnerBranch: _emberData.default.attr('string'),
    branchResponsible: _emberData.default.attr('string'),
    transactionCode: _emberData.default.attr('string'),
    showProposals: _emberData.default.attr('boolean'),
    allowContactMessages: _emberData.default.attr('boolean'),

    statusFlow: _emberData.default.attr('string'),

    scribble: _emberData.default.attr('string'),

    creditDefense: _emberData.default.attr('string'),

    pendingDocuments: _emberData.default.attr('string')
  });
});