define('ember-cli-bkf-core/models/calculator/auto-refi/operation', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    score: [(0, _emberCpValidations.validator)('presence', false), (0, _emberCpValidations.validator)('length', {
      is: 2,
      allowBlank: true
    })],
    familyMonthlyIncome: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      allowBlank: false
    })],
    incomeCommitment: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      lt: 100,
      gt: 0,
      allowBlank: false
    })],
    operationIncomeCommitment: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      lt: 100,
      gt: 0,
      allowBlank: false
    })],
    autoModelYear: [(0, _emberCpValidations.validator)('presence', false), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      allowBlank: true
    })],
    autoValue: [(0, _emberCpValidations.validator)('presence', false), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      allowBlank: true
    })],
    autoDebt: [(0, _emberCpValidations.validator)('presence', false), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: 0,
      allowBlank: true
    })],
    iof: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      allowBlank: false
    })],
    tac: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      allowBlank: false
    })],
    priceTaxYear: [(0, _emberCpValidations.validator)('presence', false), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      lt: 100,
      gt: 0,
      allowBlank: true
    })],
    priceTaxMonthly: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      lt: 100,
      gt: 0,
      allowBlank: false
    })],
    totalEffectiveCostYear: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      allowBlank: false
    })],
    totalEffectiveCostMonthly: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      lt: 100,
      gt: 0,
      allowBlank: false
    })],
    loanAmount: [(0, _emberCpValidations.validator)('presence', false), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      allowBlank: true
    })],
    loanAmountRequested: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      allowBlank: false
    })],
    contractLoanAmount: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      allowBlank: false
    })],
    pmt: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      allowBlank: false
    })],
    ltvMax: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      lt: 100,
      gt: 0,
      allowBlank: false
    })],
    ltvOperation: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      lt: 100,
      gt: 0,
      allowBlank: false
    })],
    operationMaturity: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      allowBlank: false
    })],
    maturity: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      integer: true
    })],
    paidValue: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      allowBlank: false
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    fidcApplication: _emberData.default.belongsTo('underwriting/auto-refi/fidc/application'),

    score: _emberData.default.attr('string'),
    familyMonthlyIncome: _emberData.default.attr('number'),
    incomeCommitment: _emberData.default.attr('number'),
    operationIncomeCommitment: _emberData.default.attr('number'),
    autoModelYear: _emberData.default.attr('number'),
    autoValue: _emberData.default.attr('number'),
    detranValue: _emberData.default.attr('number'),
    autoDebt: _emberData.default.attr('number'),
    iof: _emberData.default.attr('number'),
    tac: _emberData.default.attr('number'),
    priceTaxYear: _emberData.default.attr('number'),
    priceTaxMonthly: _emberData.default.attr('number'),
    totalEffectiveCostYear: _emberData.default.attr('number'),
    totalEffectiveCostMonthly: _emberData.default.attr('number'),
    loanAmount: _emberData.default.attr('number'),
    loanAmountRequested: _emberData.default.attr('number'),
    contractLoanAmount: _emberData.default.attr('number'),
    pmt: _emberData.default.attr('number'),
    ltvMax: _emberData.default.attr('number'),
    ltvOperation: _emberData.default.attr('number'),
    operationMaturity: _emberData.default.attr('number'),
    maturity: _emberData.default.attr('number'),
    paidValue: _emberData.default.attr('number')
  });
});