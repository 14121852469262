define('ember-cli-bkf-core/models/underwriting/branch', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    responsible: _emberData.default.belongsTo('consultant'),

    name: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    zipCode: _emberData.default.attr('string')
  });
});