define('ember-cli-bkf-core/models/consultant', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    email: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    fullName: _emberData.default.attr('string'),
    active: _emberData.default.attr('boolean', { defaultValue: true }),
    extension_number: _emberData.default.attr('string')
  });
});