enifed('ember-views/mixins/action_support', ['exports', 'ember-utils', 'ember-metal', 'ember-debug', 'ember-views/compat/attrs'], function (exports, _emberUtils, _emberMetal, _emberDebug, _attrs) {
  'use strict';

  /**
   @module ember
   @submodule ember-views
  */

  function validateAction(component, actionName) {
    if (actionName && actionName[_attrs.MUTABLE_CELL]) {
      actionName = actionName.value;
    }

    false && !((0, _emberMetal.isNone)(actionName) || typeof actionName === 'string' || typeof actionName === 'function') && (0, _emberDebug.assert)('The default action was triggered on the component ' + component.toString() + ', but the action name (' + actionName + ') was not a string.', (0, _emberMetal.isNone)(actionName) || typeof actionName === 'string' || typeof actionName === 'function');

    return actionName;
  }

  /**
   @class ActionSupport
   @namespace Ember
   @private
  */
  exports.default = _emberMetal.Mixin.create({
    /**
      Calls an action passed to a component.
       For example a component for playing or pausing music may translate click events
      into action notifications of "play" or "stop" depending on some internal state
      of the component:
       ```javascript
      // app/components/play-button.js
      export default Ember.Component.extend({
        click() {
          if (this.get('isPlaying')) {
            this.sendAction('play');
          } else {
            this.sendAction('stop');
          }
        }
      });
      ```
       The actions "play" and "stop" must be passed to this `play-button` component:
       ```handlebars
      {{! app/templates/application.hbs }}
      {{play-button play=(action "musicStarted") stop=(action "musicStopped")}}
      ```
       When the component receives a browser `click` event it translate this
      interaction into application-specific semantics ("play" or "stop") and
      calls the specified action.
       ```javascript
      // app/controller/application.js
      export default Ember.Controller.extend({
        actions: {
          musicStarted() {
            // called when the play button is clicked
            // and the music started playing
          },
          musicStopped() {
            // called when the play button is clicked
            // and the music stopped playing
          }
        }
      });
      ```
       If no action is passed to `sendAction` a default name of "action"
      is assumed.
       ```javascript
      // app/components/next-button.js
      export default Ember.Component.extend({
        click() {
          this.sendAction();
        }
      });
      ```
       ```handlebars
      {{! app/templates/application.hbs }}
      {{next-button action=(action "playNextSongInAlbum")}}
      ```
       ```javascript
      // app/controllers/application.js
      App.ApplicationController = Ember.Controller.extend({
        actions: {
          playNextSongInAlbum() {
            ...
          }
        }
      });
      ```
       @method sendAction
      @param [action] {String} the action to call
      @param [params] {*} arguments for the action
      @public
    */
    sendAction: function (action) {
      for (_len = arguments.length, contexts = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        contexts[_key - 1] = arguments[_key];
      }

      var actionName = void 0,
          _len,
          contexts,
          _key;

      // Send the default action
      if (action === undefined) {
        action = 'action';
      }
      actionName = (0, _emberMetal.get)(this, 'attrs.' + action) || (0, _emberMetal.get)(this, action);
      actionName = validateAction(this, actionName);

      // If no action name for that action could be found, just abort.
      if (actionName === undefined) {
        return;
      }

      if (typeof actionName === 'function') {
        actionName.apply(undefined, contexts);
      } else {
        this.triggerAction({
          action: actionName,
          actionContext: contexts
        });
      }
    },
    send: function (actionName) {
      for (_len2 = arguments.length, args = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        args[_key2 - 1] = arguments[_key2];
      }

      var action = this.actions && this.actions[actionName],
          _len2,
          args,
          _key2,
          shouldBubble;

      if (action) {
        shouldBubble = action.apply(this, args) === true;

        if (!shouldBubble) {
          return;
        }
      }

      var target = (0, _emberMetal.get)(this, 'target');
      if (target) {
        false && !(typeof target.send === 'function') && (0, _emberDebug.assert)('The `target` for ' + this + ' (' + target + ') does not have a `send` method', typeof target.send === 'function');

        target.send.apply(target, arguments);
      } else {
        false && !action && (0, _emberDebug.assert)((0, _emberUtils.inspect)(this) + ' had no action handler for: ' + actionName, action);
      }
    }
  });
});