define('ember-data-copyable/utils/is-undefined', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = isUndefined;
  function isUndefined(o) {
    return typeof o === 'undefined';
  }
});