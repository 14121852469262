define('ember-cli-bkf-core/models/crm/auto-refi/lead', ['exports', 'ember-data', 'ember-cp-validations', 'ember-cli-bkf-core/enums/purpose'], function (exports, _emberData, _emberCpValidations, _purpose) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.MAX_RETRIES = undefined;

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Validations = (0, _emberCpValidations.buildValidations)({
    purpose: [(0, _emberCpValidations.validator)('inclusion', {
      allowBlank: false,
      in: _purpose.values
    })],

    familyMonthlyIncome: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      allowBlank: false
    })],

    intendedCredit: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      positive: true,
      allowBlank: false
    })],

    zipCode: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      allowBlank: false,
      is: 8
    })],

    autoDebt: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      positive: true,
      allowBlank: false
    })],

    autoValue: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      positive: true,
      allowBlank: false
    })],

    autoBrand: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 20,
      allowBlank: false
    })],

    autoModel: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 100,
      allowBlank: false
    })],

    autoModelYear: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('date', {
      format: 'YYYY'
    })],

    autoManufacturingYear: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('date', {
      format: 'YYYY'
    })]
  });

  var MAX_RETRIES = exports.MAX_RETRIES = 5;

  exports.default = _emberData.default.Model.extend(Validations, {
    proposals: _emberData.default.hasMany('crm/auto-refi/leads/proposal'),
    application: _emberData.default.belongsTo('loan/auto-refi/bkf/application'),
    customer: _emberData.default.belongsTo('crm/customer', { inverse: 'autoRefinancingLeads' }),
    preQualification: _emberData.default.hasMany('engine/auto-refi/report'),

    completed: _emberData.default.attr('boolean', { defaultValue: false }),
    eagerPreQualification: _emberData.default.attr('boolean', { defaultValue: false }),
    purpose: _emberData.default.attr('string'),
    financingTimeMonths: _emberData.default.attr('number'),
    intendedCredit: _emberData.default.attr('number'),
    familyMonthlyIncome: _emberData.default.attr('number'),
    zipCode: _emberData.default.attr('string'),
    autoDebt: _emberData.default.attr('number'),
    autoValue: _emberData.default.attr('number'),
    autoBrand: _emberData.default.attr('string'),
    autoModel: _emberData.default.attr('string'),
    autoModelYear: _emberData.default.attr('number'),
    autoManufacturingYear: _emberData.default.attr('number'),
    autoCategory: _emberData.default.attr('string'),
    molicarCode: _emberData.default.attr('string'),
    source: _emberData.default.attr('string'),

    installmentAmount: _emberData.default.attr('number'),
    interestRate: _emberData.default.attr('number'),
    transactionCode: _emberData.default.attr('string'),

    createdAt: _emberData.default.attr('date'),

    pqResult: function pqResult() {
      return Ember.RSVP.hash({
        status: this._pqStatus(),
        application: this.get('application')
      });
    },
    _pqStatus: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
        var pqResults, retries;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                pqResults = void 0;
                retries = 0;

              case 2:
                if (!(pqResults == null || pqResults.length === 0)) {
                  _context.next = 13;
                  break;
                }

                if (!(retries++ > MAX_RETRIES)) {
                  _context.next = 5;
                  break;
                }

                return _context.abrupt('return', 'not-run');

              case 5:
                if (!(retries > 1)) {
                  _context.next = 8;
                  break;
                }

                _context.next = 8;
                return sleep(retries * 500);

              case 8:
                _context.next = 10;
                return this.hasMany('preQualification').reload();

              case 10:
                pqResults = _context.sent;
                _context.next = 2;
                break;

              case 13:
                return _context.abrupt('return', allApproved(pqResults) ? 'approved' : 'not-approved');

              case 14:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function _pqStatus() {
        return _ref.apply(this, arguments);
      }

      return _pqStatus;
    }()
  });


  function allApproved(reports) {
    var anyNotApproved = reports.map(function (report) {
      return Ember.get(report, 'status');
    }).some(function (status) {
      return status === 'NOT_APPROVED';
    });

    return !anyNotApproved;
  }

  function sleep(ms) {
    return new Ember.RSVP.Promise(function (resolve) {
      if (Ember.testing) {
        Ember.run.next(resolve);
        return;
      }

      Ember.run.later(resolve, ms);
    });
  }
});