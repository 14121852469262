define('ember-cli-bkf-core/enums/requester-kinship', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.requesterKinship = undefined;
  var requesterKinship = exports.requesterKinship = [{
    text: 'Cônjuge',
    value: 'spouse'
  }, {
    text: 'Irmão',
    value: 'sibling'
  }, {
    text: 'Convivente',
    value: 'cohabitant'
  }, {
    text: 'Pai/mãe',
    value: 'parent'
  }, {
    text: 'Outro',
    value: 'other'
  }];

  exports.default = requesterKinship;
  var values = exports.values = _lodash.default.map(requesterKinship, 'value');
});