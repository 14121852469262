define('ember-cli-bkf-core/enums/preference-expiration-day', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.preferenceExpirationDay = undefined;
  var preferenceExpirationDay = exports.preferenceExpirationDay = [{
    'text': '05',
    'value': 5
  }, {
    'text': '12',
    'value': 12
  }, {
    'text': '20',
    'value': 20
  }];

  exports.default = preferenceExpirationDay;
  var values = exports.values = _lodash.default.map(preferenceExpirationDay, 'value');
});